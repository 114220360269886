import React, { useContext, useEffect, useState } from 'react'
import PageWrapper from '../layout/PageWrapper/PageWrapper'
import Page from '../layout/Page/Page'
import Collapse from './bootstrap/Collapse'
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from './bootstrap/Card'
import FormGroup from './bootstrap/forms/FormGroup'
import Input from './bootstrap/forms/Input'
import Button, { ButtonGroup } from './bootstrap/Button'
import { useFormik } from 'formik'
import Icon from './icon/Icon'
import { getLicenseKey } from '../services/application.settings'
import AuthContext from '../contexts/authContext'
import { TableLoader, showLoader } from '../services/loader.services'
import AlertService from '../services/alert.service'
import useSortableData from '../hooks/useSortableData'
import PaginationButtons, { dataPagination } from './PaginationButtons'
import { toasts } from '../services/toaste.service'
import { getStatesAndCity, getSubModuleType } from '../services/common.service'
import { addQuotation, getQuotation, updateQuotation } from '../services/stockService'
import OpenCardComponent from '../common/components/OpenCardComponent'
import NoDataMsg from '../common/components/NoDataMsg'
import SearchableSelect from '../common/components/SearchableSelect'
import InputGroup from './bootstrap/forms/InputGroup'
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from './bootstrap/Modal'

const QuotationForm = () => {

  useEffect(() => {
    getCity()
    getDescriptionList()
    getQuotationList()
  }, [])

  const addQuotationForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      companyName: '',
      cityId: '',
      subQuotation: '',
      requirement: '',
      termsAndConditions1: '18% GST TAX EXTRA',
      termsAndConditions2: '15 DAYS CREDIT',
      termsAndConditions3: 'TO PAY',
      termsAndConditions4: 'MINIMUM ORDER QTY 1,00,000 STICKER',
    },
    validate: (values: any) => {
      const errors: {
        companyName?: string;
        cityId?: string;
        subQuotation?: string;
        requirement?: string;
        termsAndConditions1?: string;
        termsAndConditions2?: string;
        termsAndConditions3?: string;
        termsAndConditions4?: string;
      } = {};
      if (!values.companyName) {
        errors.companyName = 'Required';
      }
      if (!city?.value) {
        errors.cityId = 'Required';
      }
      if (!values.subQuotation) {
        errors.subQuotation = 'Required';
      }
      if (!values.requirement) {
        errors.requirement = 'Required';
      }
      // if (!values.termsAndConditions1) {
      //   errors.termsAndConditions1 = 'Required';
      // }
      // if (!values.termsAndConditions2) {
      //   errors.termsAndConditions2 = 'Required';
      // }
      // if (!values.termsAndConditions3) {
      //   errors.termsAndConditions3 = 'Required';
      // }
      // if (!values.termsAndConditions4) {
      //   errors.termsAndConditions4 = 'Required';
      // }
      return errors;
    },
    //validateOnChange: false,
    onSubmit: () => { addQuotationSubmit() },
  });

  const updateQuotationForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      quotationId: '',
      companyName: '',
      cityId: '',
      subQuotation: '',
      requirement: '',
      termsAndConditions1: '',
      termsAndConditions2: '',
      termsAndConditions3: '',
      termsAndConditions4: '',
    },
    validate: (values: any) => {
      const errors: {
        quotationId?: string;
        companyName?: string;
        cityId?: string;
        subQuotation?: string;
        requirement?: string;
        termsAndConditions1?: string;
        termsAndConditions2?: string;
        termsAndConditions3?: string;
        termsAndConditions4?: string;
      } = {};
      if (!values.companyName) {
        errors.companyName = 'Required';
      }
      if (!city?.value) {
        errors.cityId = 'Required';
      }
      if (!values.subQuotation) {
        errors.subQuotation = 'Required';
      }
      if (!values.requirement) {
        errors.requirement = 'Required';
      }
      // if (!values.termsAndConditions1) {
      //   errors.termsAndConditions1 = 'Required';
      // }
      // if (!values.termsAndConditions2) {
      //   errors.termsAndConditions2 = 'Required';
      // }
      // if (!values.termsAndConditions3) {
      //   errors.termsAndConditions3 = 'Required';
      // }
      // if (!values.termsAndConditions4) {
      //   errors.termsAndConditions4 = 'Required';
      // }
      return errors;
    },
    //validateOnChange: false,
    onSubmit: () => { updateQuotationSubmit() },
  });

  const columnVisibileForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      searchInput: '',
      available: false,
    },
    validate: (values) => { },
    //validateOnChange: false,
    onSubmit: () => { },
  });

  const [isOpenListCard, setIsOpenListCard] = useState(false)
  const { userAccountId, userTypeId } = useContext(AuthContext);
  const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
  const [isOpen, setIsOpen] = useState(false);
  const [quotationDetailsData, setQuotationDetailsData] = useState<any>([])
  const [noDataMsg, setNoDataMsg] = useState('')

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { items, requestSort, getClassNamesFor } = useSortableData(quotationDetailsData);
  const onCurrentPageData = dataPagination(items, currentPage, perPage);

  const [cityData, setCityData] = useState([])
  const [inputList, setInputList] = useState<any>([])
  const [count, setCount] = useState(2)
  const [quotationDetails, setQuotationDetails] = useState<any>([])
  const [city, setCity] = useState<any>([])
  const [descriptionDetailsData, setDescriptionDetailsData] = useState<any>([])
  const [dataStatus, setDataStatus] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)

  // Filter
  const filteredData = onCurrentPageData.filter(
    (i) =>
      i.sno !== null && i.sno.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
      i.companyName !== null && i.companyName.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
      i.cityName !== null && i.cityName.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
      i.subQuotation !== null && i.subQuotation.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
      i.requirement !== null && i.requirement.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
      i.termsAndConditions1 !== null && i.termsAndConditions1.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
      i.termsAndConditions2 !== null && i.termsAndConditions2.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
      i.termsAndConditions3 !== null && i.termsAndConditions3.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
      i.termsAndConditions4 !== null && i.termsAndConditions4.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()),
  );

  function onPrint(i: any) {
    if (i != undefined) {
      let selectedData = i
      window.open(selectedData.pdfPath);
    }
  }

  function selectCity(e: any) {
    setCity(e)
  }

  function addAndNew() {
    setIsOpenListCard(true)
    setInputList([{ quotationDetailsId: 0, description: '', rate: '', quantity: '', amount: '', isDeacriptionSelect: descriptionDetailsData != '' ? true : false, isRateSelect: false }])
  }

  function onActivate(i: any) {

    if (i != undefined) {
      let selectedQuotation = i
      setIsOpenModal(true)
      setCity({ value: selectedQuotation.cityId, label: selectedQuotation.cityName })
      updateQuotationForm.setValues({
        quotationId: selectedQuotation.quotationId,
        companyName: selectedQuotation.companyName,
        cityId: selectedQuotation.cityId,
        subQuotation: selectedQuotation.subQuotation,
        requirement: selectedQuotation.requirement,
        termsAndConditions1: selectedQuotation.termsAndConditions1,
        termsAndConditions2: selectedQuotation.termsAndConditions2,
        termsAndConditions3: selectedQuotation.termsAndConditions3,
        termsAndConditions4: selectedQuotation.termsAndConditions4,
      });

      for (let i = 0; i < selectedQuotation.quotationDetails.length; i++) {
        let details = selectedQuotation.quotationDetails[i]
        inputList.push({ quotationDetailsId: details.quotationDetailsId, description: details.description, rate: details.rate, quantity: details.quantity, amount: details.amount, isDeacriptionSelect: true, isRateSelect: true, descriptionData: { value: details.description, label: details.description }, isSelect: 1, isEdit: 1 })

        quotationDetails.push({ quotationDetailsId: details.quotationDetailsId, description: details.description, rate: details.rate, quantity: details.quantity, amount: details.amount, isDeacriptionSelect: true, isRateSelect: true })
      }
    }
  }

  function add() {
    for (let i = 0; i < count; i++) {
      setCount(count + 1)
    }
    setInputList([...inputList, { quotationDetailsId: 0, description: '', rate: '', quantity: '', amount: '', isDeacriptionSelect: descriptionDetailsData != '' ? true : false, isRateSelect: false }])
  }

  function handleremove(description: any, rate: any, index: any) {
    const list = [...inputList];
    list.splice(index, 1)
    setInputList(list)
    let selectedItem
    quotationDetails.forEach((item: any) => {
      if (item['description'] == description && item['rate'] == rate) {
        selectedItem = item
        quotationDetails.splice(quotationDetails.indexOf(selectedItem), 1);
      }
    });
  }

  function handleInputChange(description: any, rate: any, quantity: any, amount: any, isDeacriptionSelect: any, isRateSelect: any, index: any, isSelect: any) {

    const list = [...inputList];
    let descriptionValue = isSelect == 3 ? '' : description;
    let amountValue = rate * quantity
    let selectedDescription = descriptionDetailsData != '' ? descriptionDetailsData.filter((item: any) => item.subModuleType == descriptionValue) : null
    list[index]['description'] = descriptionValue
    list[index]['quantity'] = quantity;
    list[index]['rate'] = isSelect == 1 || isSelect == 2 ? rate : descriptionDetailsData != '' ? selectedDescription[0]?.descriptionRate : ''
    list[index]['amount'] = quantity != '' ? amountValue : '';
    list[index]['isDeacriptionSelect'] = isDeacriptionSelect;
    list[index]['isRateSelect'] = isRateSelect;
    list[index]['isSelect'] = isSelect;
    list[index]['descriptionData'] = descriptionValue;
    setInputList(list);
    setQuotationDetails(list)
  }

  function setQuotationFormDetails() {
    return ({
      licenseKey: getLicenseKey,
      companyName: addQuotationForm.values.companyName,
      cityId: city.value,
      subQuotation: addQuotationForm.values.subQuotation,
      requirement: addQuotationForm.values.requirement,
      termsAndConditions1: addQuotationForm.values.termsAndConditions1 != '' ? addQuotationForm.values.termsAndConditions1 : null,
      termsAndConditions2: addQuotationForm.values.termsAndConditions2 != '' ? addQuotationForm.values.termsAndConditions2 : null,
      termsAndConditions3: addQuotationForm.values.termsAndConditions3 != '' ? addQuotationForm.values.termsAndConditions3 : null,
      termsAndConditions4: addQuotationForm.values.termsAndConditions4 != '' ? addQuotationForm.values.termsAndConditions4 : null,
      quotationDetails: quotationDetails,
      userAccountId: userAccountId,
    })
  }

  function setUpdateQuotationFormDetails() {
    return ({
      licenseKey: getLicenseKey,
      quotationId: updateQuotationForm.values.quotationId,
      companyName: updateQuotationForm.values.companyName,
      cityId: city.value,
      subQuotation: updateQuotationForm.values.subQuotation,
      requirement: updateQuotationForm.values.requirement,
      termsAndConditions1: updateQuotationForm.values.termsAndConditions1 != '' ? updateQuotationForm.values.termsAndConditions1 : null,
      termsAndConditions2: updateQuotationForm.values.termsAndConditions2 != '' ? updateQuotationForm.values.termsAndConditions2 : null,
      termsAndConditions3: updateQuotationForm.values.termsAndConditions3 != '' ? updateQuotationForm.values.termsAndConditions3 : null,
      termsAndConditions4: updateQuotationForm.values.termsAndConditions4 != '' ? updateQuotationForm.values.termsAndConditions4 : null,
      quotationDetails: quotationDetails,
      userAccountId: userAccountId,
    })
  }

  function addQuotationSubmit() {
    let filData: any = quotationDetails.filter((item: any) => item.description == '' || item.description == undefined || item.amount == 0)
    showLoader(true)
    let quotationPostData = setQuotationFormDetails()
    if (quotationDetails.length > 0) {
      if (addQuotationForm.isValid && filData == '') {
        addQuotation(quotationPostData,
          (response) => {
            const data = response.data;
            if (data.success == true) {
              showLoader(false)
              setAlertStatus({ message: data.message, type: "success" });
              setIsOpen(true);
              closeAndReset()
            }
            else if (data.success == false) {
              showLoader(false)
              setAlertStatus({ message: data.message, type: "error" });
              setIsOpen(true);
            }
            else {
              let errorCode = response.data.error[0].error_code;
              let errorDescription = response.data.error[0].error_description;
              showLoader(false)
              setAlertStatus({ message: errorDescription, type: "error" });
              setIsOpen(true);
            }
          }, (error) => {
            showLoader(false)
            setAlertStatus({ message: error, type: "error" });
            setIsOpen(true);
          }
        )
      } else if (addQuotationForm.isValid == false || filData != '') {
        showLoader(false)
        toasts("Please fill all details", "Error");
      }
    } else {
      showLoader(false)
      toasts("Please Enter Description details", "Error");
    }
  }

  function getQuotationList() {
    setDataStatus(false)
    getQuotation(0,
      (response) => {
        if (response.data.success) {
          let data = response.data.data.quotation;
          if (data != undefined) {
            setDataStatus(true)
            setQuotationDetailsData(data)
            for (let i = 0; i < data.length; i++) {
              data[i].sno = i + 1;
            }
          } else {
            toasts("Undefined Data", "Error")
          }
        }
        else if (response.data.success === false) {
          setNoDataMsg(response.data.message)
          setDataStatus(true)
        } else {
          let errorCode = response.data.error[0].error_code;
          let errorDescription = response.data.error[0].error_description;
          toasts(errorDescription, "Error")
          setDataStatus(true)
        }
      }, error => {
        toasts(error, "Error")
        setDataStatus(true)
      }
    )
  }

  function updateQuotationSubmit() {
    let filData: any = quotationDetails.filter((item: any) => item.description == '' || item.description == undefined || item.amount == 0)
    showLoader(true)
    let quotationPostData = setUpdateQuotationFormDetails()
    if (quotationDetails.length > 0) {
      if (updateQuotationForm.isValid && filData == '') {
        updateQuotation(quotationPostData,
          (response) => {
            const data = response.data;
            if (data.success == true) {
              showLoader(false)
              setAlertStatus({ message: data.message, type: "success" });
              setIsOpen(true);
              closeAndReset()
            }
            else if (data.success == false) {
              showLoader(false)
              setAlertStatus({ message: data.message, type: "error" });
              setIsOpen(true);
            }
            else {
              let errorCode = response.data.error[0].error_code;
              let errorDescription = response.data.error[0].error_description;
              showLoader(false)
              setAlertStatus({ message: errorDescription, type: "error" });
              setIsOpen(true);
            }
          }, (error) => {
            showLoader(false)
            setAlertStatus({ message: error, type: "error" });
            setIsOpen(true);
          }
        )
      } else if (updateQuotationForm.isValid == false || filData != '') {
        showLoader(false)
        toasts("Please fill all details", "Error");
      }
    } else {
      showLoader(false)
      toasts("Please Enter Description details", "Error");
    }
  }

  function getCity() {
    getStatesAndCity(31,
      (response) => {
        if (response.data.success) {
          let data = response.data.data.statesAndCity[0].city;
          if (data != undefined) {
            setCityData(data)
            setIsOpenListCard(false);
            for (let i = 0; i < data.length; i++) {
              data[i].sno = i + 1;
            }
          } else {
            toasts("Undefined Data", "Error")
          }
        }
        else if (response.data.success === false) {
          setNoDataMsg(response.data.message)
          setIsOpenListCard(false);
        } else {
          let errorCode = response.data.error[0].error_code;
          let errorDescription = response.data.error[0].error_description;
          toasts(errorDescription, "Error")
        }
      }, error => {
        toasts(error, "Error")
      }
    )
  }

  function getDescriptionList() {
    getSubModuleType(2, 0,
      (response) => {
        if (response.data.success) {
          let data = response.data.data.subModuleType;
          if (data != undefined) {
            setDescriptionDetailsData(data)
          } else {
            toasts("Undefined Data", "Error")
          }
        }
        else if (response.data.success === false) {
          // toasts(response.data.message, "Error")
        } else {
          let errorCode = response.data.error[0].error_code;
          let errorDescription = response.data.error[0].error_description;
          toasts(errorDescription, "Error")
        }
      }, error => {
        toasts(error, "Error")
      }
    )
  }

  function closeAndReset() {
    addQuotationForm.resetForm()
    updateQuotationForm.resetForm()
    getQuotationList()
    setInputList([])
    setQuotationDetails([])
    setIsOpenListCard(false)
    setCity([])
    getDescriptionList()
    setIsOpenModal(false)
  }

  return (
    <PageWrapper title={`Quotation Form`}>
      <Page>
        <Collapse isOpen={isOpenListCard}>
          <Card stretch data-tour='list' className='d-print-none' tag="form" noValidate onSubmit={addQuotationForm.handleSubmit}>
            <CardHeader borderSize={1}>
              <CardLabel icon='PostAdd' iconColor='info'>
                <CardTitle>
                  Quotation Form
                </CardTitle>
              </CardLabel>
            </CardHeader>
            <CardBody>
              <div className='row g-4'>
                <div className='col-3'>
                  <FormGroup id='companyName' label='Company Name' isFloating>
                    <Input onChange={addQuotationForm.handleChange}
                      value={addQuotationForm.values.companyName}
                      isValid={addQuotationForm.isValid}
                      onBlur={addQuotationForm.handleBlur}
                      isTouched={addQuotationForm.touched.companyName}
                      invalidFeedback={addQuotationForm.errors.companyName}
                      placeholder="Enter Company Name" />
                  </FormGroup>
                </div>
                <div className='col-3'>
                  <FormGroup id='cityId' label='City' isFloating>
                    <SearchableSelect
                      isFloating
                      ariaLabel={''}
                      placeholder='Select City'
                      className="form-control"
                      value={city}
                      isValid={addQuotationForm.isValid}
                      isTouched={addQuotationForm.touched.cityId}
                      invalidFeedback={addQuotationForm.errors.cityId}
                      onBlur={addQuotationForm.handleBlur}
                      list={cityData.map((data: any) => (
                        { value: data.cityId, label: data.cityName }
                      ))}
                      onChange={e => selectCity(e)} />
                  </FormGroup>
                </div>
                <div className='col-3'>
                  <FormGroup id='subQuotation' label='Sub Quotation' isFloating>
                    <Input onChange={addQuotationForm.handleChange}
                      value={addQuotationForm.values.subQuotation}
                      isValid={addQuotationForm.isValid}
                      onBlur={addQuotationForm.handleBlur}
                      isTouched={addQuotationForm.touched.subQuotation}
                      invalidFeedback={addQuotationForm.errors.subQuotation}
                      placeholder="Enter Sub Quotation" />
                  </FormGroup>
                </div>
                <div className='col-3'>
                  <FormGroup id='requirement' label='Requirement' isFloating>
                    <Input onChange={addQuotationForm.handleChange}
                      value={addQuotationForm.values.requirement}
                      isValid={addQuotationForm.isValid}
                      onBlur={addQuotationForm.handleBlur}
                      isTouched={addQuotationForm.touched.requirement}
                      invalidFeedback={addQuotationForm.errors.requirement}
                      placeholder="Enter Requirement" />
                  </FormGroup>
                </div>
                <div className='col-3'>
                  <FormGroup id='termsAndConditions1' label='Terms & Conditions 1' isFloating>
                    <Input onChange={addQuotationForm.handleChange}
                      value={addQuotationForm.values.termsAndConditions1}
                      isValid={addQuotationForm.isValid}
                      onBlur={addQuotationForm.handleBlur}
                      isTouched={addQuotationForm.touched.termsAndConditions1}
                      invalidFeedback={addQuotationForm.errors.termsAndConditions1}
                      placeholder="Enter Terms & Conditions 1" />
                  </FormGroup>
                </div>
                <div className='col-3'>
                  <FormGroup id='termsAndConditions2' label='Terms & Conditions 2' isFloating>
                    <Input onChange={addQuotationForm.handleChange}
                      value={addQuotationForm.values.termsAndConditions2}
                      isValid={addQuotationForm.isValid}
                      onBlur={addQuotationForm.handleBlur}
                      isTouched={addQuotationForm.touched.termsAndConditions2}
                      invalidFeedback={addQuotationForm.errors.termsAndConditions2}
                      placeholder="Enter Terms & Conditions 2" />
                  </FormGroup>
                </div>
                <div className='col-3'>
                  <FormGroup id='termsAndConditions3' label='Terms & Conditions 3' isFloating>
                    <Input onChange={addQuotationForm.handleChange}
                      value={addQuotationForm.values.termsAndConditions3}
                      isValid={addQuotationForm.isValid}
                      onBlur={addQuotationForm.handleBlur}
                      isTouched={addQuotationForm.touched.termsAndConditions3}
                      invalidFeedback={addQuotationForm.errors.termsAndConditions3}
                      placeholder="Enter Terms & Conditions 3" />
                  </FormGroup>
                </div>
                <div className='col-3'>
                  <FormGroup id='termsAndConditions4' label='Terms & Conditions 4' isFloating>
                    <Input onChange={addQuotationForm.handleChange}
                      value={addQuotationForm.values.termsAndConditions4}
                      isValid={addQuotationForm.isValid}
                      onBlur={addQuotationForm.handleBlur}
                      isTouched={addQuotationForm.touched.termsAndConditions4}
                      invalidFeedback={addQuotationForm.errors.termsAndConditions4}
                      placeholder="Enter Terms & Conditions 3" />
                  </FormGroup>
                </div>
                <div className='col-md-12'>
                  <FormGroup className='d-flex justify-content-end'>
                    <Button className="mt-2" icon="Add" color='primary' size={'sm'} onClick={add}>
                      Add Description
                    </Button>
                  </FormGroup>
                </div>
                <div className="col-12 mt-4">
                  <table className='table table-bordered table-hover mt-2'>
                    <thead>
                      <tr className='table-primary text-center'>
                        <th style={{ width: '35%' }}>DESCRIPTION</th>
                        <th style={{ width: '20%' }}>RATE</th>
                        <th style={{ width: '15%' }}>QUANTITY</th>
                        <th style={{ width: '15%' }}>AMOUNT</th>
                        {inputList.length > 1 ?
                          <th style={{ width: '3%' }}></th> : null
                        }
                      </tr>
                    </thead>
                    {inputList.map((x: any, i: any) => (
                      <tbody key={i}>
                        <tr>
                          <td>
                            <InputGroup>
                              {x.isDeacriptionSelect ?
                                <FormGroup id='description' label='Description' isFloating>
                                  <SearchableSelect
                                    isFloating
                                    ariaLabel={''}
                                    value={x.description != '' ? { value: x.description, label: x.description } : ''}
                                    placeholder='Select Description'
                                    className="form-control"
                                    onChange={(e: any) => handleInputChange(e.value, x.rate, x.quantity, x.amount, x.isDeacriptionSelect, x.isRateSelect, i, 0)}
                                    list={descriptionDetailsData.map((data: any) => (
                                      { value: data.subModuleType, label: data.subModuleType }
                                    ))} />
                                </FormGroup> :
                                <FormGroup id='description' label='Description' isFloating>
                                  <Input onChange={(e: any) => handleInputChange(e.target.value, x.rate, x.quantity, x.amount, x.isDeacriptionSelect, x.isRateSelect, i, 1)}
                                    value={x.description}
                                    isValid={addQuotationForm.isValid}
                                    onBlur={addQuotationForm.handleBlur}
                                    placeholder="Enter Description" />
                                </FormGroup>
                              }
                              <Button isOutline color='primary' icon='Add' style={{ height: '46px' }} onClick={(e: any) => handleInputChange(x.description, x.rate, x.quantity, x.amount, !x.isDeacriptionSelect, x.isRateSelect, i, 3)}></Button>
                            </InputGroup>
                          </td>
                          <td>
                            <FormGroup id='rate' label='Rate' isFloating>
                              <Input onChange={(e: any) => handleInputChange(x.description, e.target.value, x.quantity, x.amount, x.isDeacriptionSelect, x.isRateSelect, i, 2)}
                                value={x.rate}
                                isValid={addQuotationForm.isValid}
                                onBlur={addQuotationForm.handleBlur}
                                type='number'
                                placeholder="Enter Rate" />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup id='quantity' label='Quantity' isFloating>
                              <Input onChange={(e: any) => handleInputChange(x.description, x.rate, e.target.value, x.amount, x.isDeacriptionSelect, x.isRateSelect, i, x.isSelect)}
                                value={x.quantity}
                                isValid={addQuotationForm.isValid}
                                onBlur={addQuotationForm.handleBlur}
                                type='number'
                                placeholder="Enter Quantity" />
                            </FormGroup>
                          </td>
                          <td>
                            <FormGroup id='amount' label='Amount' isFloating>
                              <Input onChange={(e: any) => handleInputChange(x.description, x.rate, x.quantity, e.target.value, x.isDeacriptionSelect, x.isRateSelect, i, x.isSelect)}
                                value={x.amount}
                                isValid={addQuotationForm.isValid}
                                onBlur={addQuotationForm.handleBlur}
                                type='number'
                                disabled
                                placeholder="Enter Amount" />
                            </FormGroup>
                          </td>
                          {inputList.length > 1 ?
                            <td>
                              <Button className='mt-2' color='danger' isLight icon='Close'
                                onClick={() => handleremove(x.description, x.rate, i)}
                              />
                            </td> : null
                          }
                        </tr>
                      </tbody>
                    ))}
                  </table>
                </div>
                <div className='col-md-12'>
                  <FormGroup className='d-flex justify-content-center'>
                    <Button icon="Check" color='primary' onClick={addQuotationForm.handleSubmit}>
                      Submit
                    </Button>
                  </FormGroup>
                </div>
              </div>
            </CardBody>
          </Card>
        </Collapse>

        {isOpenListCard ?
          <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={closeAndReset} isLoader={false} /> : null
        }

        <Card stretch data-tour='list'>
          <CardHeader borderSize={1}>
            <CardLabel icon='List' iconColor='info' className="col-lg-7">
              <CardTitle tag='div' className='h5'>Quotation Form List</CardTitle>
            </CardLabel>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
              <Icon className="mt-1" icon='Search' size='2x' color='primary' />
              <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibileForm.handleChange} value={columnVisibileForm.values.searchInput} />
            </ButtonGroup>

            <CardActions className="d-print-none">
              <Button color='primary' icon='Add' isLight onClick={addAndNew}>
                Add
              </Button>
            </CardActions>
          </CardHeader>
          <CardBody className='table-responsive' isScrollable>
            {!dataStatus ?
              <TableLoader /> :
              <table className='table table-modern table-hover'>
                <thead>
                  <tr>
                    <th scope='col' onClick={() => requestSort('sno')} className='cursor-pointer text-decoration-underline'>S.No <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' /></th>
                    <th scope='col' onClick={() => requestSort('quotationNo')} className='cursor-pointer text-decoration-underline'>Quotation No <Icon size='lg' className={getClassNamesFor('quotationNo')} icon='FilterList' /></th>
                    <th scope='col' onClick={() => requestSort('companyName')} className='cursor-pointer text-decoration-underline'>Company Name <Icon size='lg' className={getClassNamesFor('companyName')} icon='FilterList' /></th>
                    <th scope='col' onClick={() => requestSort('cityName')} className='cursor-pointer text-decoration-underline'>City Name <Icon size='lg' className={getClassNamesFor('cityName')} icon='FilterList' /></th>
                    <th scope='col' onClick={() => requestSort('quotationDate')} className='cursor-pointer text-decoration-underline'>Quotation Date<Icon size='lg' className={getClassNamesFor('quotationDate')} icon='FilterList' /></th>
                    <th><div style={{ marginLeft: "60px" }}>Action</div></th>
                  </tr>
                </thead>
                <tbody>
                  {quotationDetailsData != "" ? <>{filteredData.map((item: any) => (<tr key={item.quotationNo}><td>{item.sno}</td><td>{item.quotationNo}</td><td>{item.companyName}</td><td>{item.cityName}</td><td>{item.quotationDate}</td><td className="d-print-none"><Button color='primary' isLight icon='Edit' onClick={() => onActivate(item)}>Edit</Button> <Button color='success' isLight icon='Download' aria-label='Download' onClick={() => onPrint(item)}>Download</Button></td></tr>))}</> : <NoDataMsg columnsCount={10} msg={noDataMsg} />}
                </tbody>
              </table>
            }
          </CardBody>
          <PaginationButtons className="d-print-none" data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
        </Card>

        <Modal
          setIsOpen={setIsOpenModal}
          isOpen={isOpenModal}
          titleId='upcomingEdit'
          isCentered
          isStaticBackdrop
          size='xl'>
          <ModalHeader setIsOpen={closeAndReset}>
            <ModalTitle id='examplemailcontent'>
              <div className="h4">Update Quotation Form
              </div>
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <div className='row g-4'>
              <div className='col-4'>
                <FormGroup id='companyName' label='Company Name' isFloating>
                  <Input onChange={updateQuotationForm.handleChange}
                    value={updateQuotationForm.values.companyName}
                    isValid={updateQuotationForm.isValid}
                    onBlur={updateQuotationForm.handleBlur}
                    isTouched={updateQuotationForm.touched.companyName}
                    invalidFeedback={updateQuotationForm.errors.companyName}
                    placeholder="Enter Company Name" />
                </FormGroup>
              </div>
              <div className='col-4'>
                <FormGroup id='cityId' label='City' isFloating>
                  <SearchableSelect
                    isFloating
                    ariaLabel={''}
                    placeholder='Select City'
                    className="form-control"
                    value={city}
                    isValid={updateQuotationForm.isValid}
                    isTouched={updateQuotationForm.touched.cityId}
                    invalidFeedback={updateQuotationForm.errors.cityId}
                    onBlur={updateQuotationForm.handleBlur}
                    list={cityData.map((data: any) => (
                      { value: data.cityId, label: data.cityName }
                    ))}
                    onChange={e => selectCity(e)} />
                </FormGroup>
              </div>
              <div className='col-4'>
                <FormGroup id='subQuotation' label='Sub Quotation' isFloating>
                  <Input onChange={updateQuotationForm.handleChange}
                    value={updateQuotationForm.values.subQuotation}
                    isValid={updateQuotationForm.isValid}
                    onBlur={updateQuotationForm.handleBlur}
                    isTouched={updateQuotationForm.touched.subQuotation}
                    invalidFeedback={updateQuotationForm.errors.subQuotation}
                    placeholder="Enter Sub Quotation" />
                </FormGroup>
              </div>
              <div className='col-4'>
                <FormGroup id='requirement' label='Requirement' isFloating>
                  <Input onChange={updateQuotationForm.handleChange}
                    value={updateQuotationForm.values.requirement}
                    isValid={updateQuotationForm.isValid}
                    onBlur={updateQuotationForm.handleBlur}
                    isTouched={updateQuotationForm.touched.requirement}
                    invalidFeedback={updateQuotationForm.errors.requirement}
                    placeholder="Enter Requirement" />
                </FormGroup>
              </div>
              <div className='col-4'>
                <FormGroup id='termsAndConditions1' label='Terms & Conditions 1' isFloating>
                  <Input onChange={updateQuotationForm.handleChange}
                    value={updateQuotationForm.values.termsAndConditions1}
                    isValid={updateQuotationForm.isValid}
                    onBlur={updateQuotationForm.handleBlur}
                    isTouched={updateQuotationForm.touched.termsAndConditions1}
                    invalidFeedback={updateQuotationForm.errors.termsAndConditions1}
                    placeholder="Enter Terms & Conditions 1" />
                </FormGroup>
              </div>
              <div className='col-4'>
                <FormGroup id='termsAndConditions2' label='Terms & Conditions 2' isFloating>
                  <Input onChange={updateQuotationForm.handleChange}
                    value={updateQuotationForm.values.termsAndConditions2}
                    isValid={updateQuotationForm.isValid}
                    onBlur={updateQuotationForm.handleBlur}
                    isTouched={updateQuotationForm.touched.termsAndConditions2}
                    invalidFeedback={updateQuotationForm.errors.termsAndConditions2}
                    placeholder="Enter Terms & Conditions 2" />
                </FormGroup>
              </div>
              <div className='col-4'>
                <FormGroup id='termsAndConditions3' label='Terms & Conditions 3' isFloating>
                  <Input onChange={updateQuotationForm.handleChange}
                    value={updateQuotationForm.values.termsAndConditions3}
                    isValid={updateQuotationForm.isValid}
                    onBlur={updateQuotationForm.handleBlur}
                    isTouched={updateQuotationForm.touched.termsAndConditions3}
                    invalidFeedback={updateQuotationForm.errors.termsAndConditions3}
                    placeholder="Enter Terms & Conditions 3" />
                </FormGroup>
              </div>
              <div className='col-4'>
                <FormGroup id='termsAndConditions4' label='Terms & Conditions 4' isFloating>
                  <Input onChange={updateQuotationForm.handleChange}
                    value={updateQuotationForm.values.termsAndConditions4}
                    isValid={updateQuotationForm.isValid}
                    onBlur={updateQuotationForm.handleBlur}
                    isTouched={updateQuotationForm.touched.termsAndConditions4}
                    invalidFeedback={updateQuotationForm.errors.termsAndConditions4}
                    placeholder="Enter Terms & Conditions 3" />
                </FormGroup>
              </div>
              <div className='col-md-4'>
                <FormGroup className='d-flex justify-content-end'>
                  <Button className="mt-4" icon="Add" color='primary' size={'sm'} onClick={add}>
                    Add Description
                  </Button>
                </FormGroup>
              </div>
              <div className="col-12 mt-4">
                <table className='table table-bordered table-hover mt-2'>
                  <thead>
                    <tr className='table-primary text-center'>
                      <th style={{ width: '35%' }}>DESCRIPTION</th>
                      <th style={{ width: '20%' }}>RATE</th>
                      <th style={{ width: '15%' }}>QUANTITY</th>
                      <th style={{ width: '15%' }}>AMOUNT</th>
                      {inputList.length > 1 ?
                        <th style={{ width: '3%' }}></th> : null
                      }
                    </tr>
                  </thead>
                  {inputList.map((x: any, i: any) => (
                    <tbody key={i}>
                      <tr>
                        <td>
                          <InputGroup>
                            {x.isDeacriptionSelect ?
                              <FormGroup id='description' label='Description' isFloating>
                                <SearchableSelect
                                  isFloating
                                  ariaLabel={''}
                                  value={x.description != '' ? { value: x.description, label: x.description } : ''}
                                  placeholder='Select Description'
                                  className="form-control"
                                  onChange={(e: any) => handleInputChange(e.value, x.rate, x.quantity, x.amount, x.isDeacriptionSelect, x.isRateSelect, i, 0)}
                                  list={descriptionDetailsData.map((data: any) => (
                                    { value: data.subModuleType, label: data.subModuleType }
                                  ))} />
                              </FormGroup> :
                              <FormGroup id='description' label='Description' isFloating>
                                <Input onChange={(e: any) => handleInputChange(e.target.value, x.rate, x.quantity, x.amount, x.isDeacriptionSelect, x.isRateSelect, i, 1)}
                                  value={x.description}
                                  isValid={updateQuotationForm.isValid}
                                  onBlur={updateQuotationForm.handleBlur}
                                  placeholder="Enter Description" />
                              </FormGroup>
                            }
                            <Button isOutline color='primary' icon='Add' style={{ height: '46px' }} onClick={(e: any) => handleInputChange(x.description, x.rate, x.quantity, x.amount, !x.isDeacriptionSelect, x.isRateSelect, i, 3)}></Button>
                          </InputGroup>
                        </td>
                        <td>
                          <FormGroup id='rate' label='Rate' isFloating>
                            <Input onChange={(e: any) => handleInputChange(x.description, e.target.value, x.quantity, x.amount, x.isDeacriptionSelect, x.isRateSelect, i, 2)}
                              value={x.rate}
                              isValid={updateQuotationForm.isValid}
                              onBlur={updateQuotationForm.handleBlur}
                              type='number'
                              placeholder="Enter Rate" />
                          </FormGroup>
                        </td>
                        <td>
                          <FormGroup id='quantity' label='Quantity' isFloating>
                            <Input onChange={(e: any) => handleInputChange(x.description, x.rate, e.target.value, x.amount, x.isDeacriptionSelect, x.isRateSelect, i, x.isSelect)}
                              value={x.quantity}
                              isValid={updateQuotationForm.isValid}
                              onBlur={updateQuotationForm.handleBlur}
                              type='number'
                              placeholder="Enter Quantity" />
                          </FormGroup>
                        </td>
                        <td>
                          <FormGroup id='amount' label='Amount' isFloating>
                            <Input onChange={(e: any) => handleInputChange(x.description, x.rate, x.quantity, e.target.value, x.isDeacriptionSelect, x.isRateSelect, i, x.isSelect)}
                              value={x.amount}
                              isValid={updateQuotationForm.isValid}
                              onBlur={updateQuotationForm.handleBlur}
                              type='number'
                              disabled
                              placeholder="Enter Amount" />
                          </FormGroup>
                        </td>
                        {inputList.length > 1 ?
                          <td>
                            <Button className='mt-2' color='danger' isLight icon='Close'
                              onClick={() => handleremove(x.description, x.rate, i)}
                            />
                          </td> : null
                        }
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color='info'
              isOutline
              className='border-0'
              onClick={closeAndReset}>
              Close
            </Button>
            <Button color='info' type="submit" icon="Save" onClick={updateQuotationForm.handleSubmit}>
              Update
            </Button>
          </ModalFooter>
        </Modal>

        <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
      </Page>
    </PageWrapper >
  )
}

export default QuotationForm