
export const getAPIURL = () => {

    // const apiUrl = 'http://localhost/omniwheels/bb-viswa-api/';
    // const apiUrl = 'https://api.viswa-dev.academyado.com/';
    const apiUrl = 'https://api.viswa.academyado.com/';    
    
    return apiUrl;
}

// getLicenseKey
export const getLicenseKey = 5566;