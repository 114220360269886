import React, { useContext, useEffect, useState } from 'react'
import PageWrapper from '../../layout/PageWrapper/PageWrapper'
import Page from '../../layout/Page/Page'
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card'
import Button, { ButtonGroup } from '../bootstrap/Button'
import { useFormik } from 'formik'
import Icon from '../icon/Icon'
import AuthContext from '../../contexts/authContext'
import { TableLoader, showLoader } from '../../services/loader.services'
import NoDataMsg from '../../common/components/NoDataMsg'
import useSortableData from '../../hooks/useSortableData'
import PaginationButtons, { dataPagination } from '../PaginationButtons'
import { addDispatchDetails, getDispatchDetails, getRibbonRollStockCount } from '../../services/stockService'
import { toasts } from '../../services/toaste.service'
import { getLicenseKey } from '../../services/application.settings'
import FormGroup from '../bootstrap/forms/FormGroup'
import Select from '../bootstrap/forms/Select'
import AlertService from '../../services/alert.service'
import { getOrderReceivedDetails, getProductionEntryCount, getProductionEntryList } from '../../services/orderService'
import Collapse from '../bootstrap/Collapse'
import SearchableSelect from '../../common/components/SearchableSelect'
import Input from '../bootstrap/forms/Input'

const DispatchRoll = () => {

    useEffect(() => {
        getDispatchDetailsList()
        getOrderReceivedDetailsList()
    }, [])

    const dispatchRollForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            orderReceivedDetailsId: ''
        },
        validate: (values: any) => {
            const errors: {
                orderReceivedDetailsId?: string;
            } = {};
            if (!orderReceivedDetailsId?.value) {
                errors.orderReceivedDetailsId = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { addDispatchDetailsSubmit() },
    });

    const columnVisibileForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [dispatchRollData, setDispatchRollData] = useState<any>([])
    const [orderReceivedDetailsData, setOrderReceivedDetailsData] = useState<any>([])
    const [noDataMsg, setNoDataMsg] = useState('')
    const [sizeOfRibbonData, setSizeOfRibbonData] = useState<any>([])
    const [lotDetailsDataForRibbon, setLotDetailsDataForRibbon] = useState([])
    const [assignCompanyToCone, setAssignCompanyToCone] = useState(false)
    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const [rollDetails, setRollDetails] = useState<any>([])
    const [productionEntryData, setProductionEntryData] = useState<any>([])
    const [isDupliteEntry, setIsDupliteEntry] = useState<any>([])
    const [barcodeStickerData, setBarcodeStickerData] = useState<any>([])
    const [ribbonRollData, setRibbonRollData] = useState<any>([])
    const [isCheck, setIsCheck] = useState<any>([])
    const [show, setShow] = useState<any>(false)
    const [inputList, setInputList] = useState<any>([])
    const [count, setCount] = useState(2)

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(dispatchRollData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [dataStatus, setDataStatus] = useState(false)

    const [isOpen, setIsOpen] = useState(false);
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [orderReceivedDetailsId, setOrderReceivedDetailsId] = useState<any>('')

    // Filter
    const filteredData = onCurrentPageData.filter(
        (i) =>
            i.customerName !== null && i.customerName.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.poNumber !== null && i.poNumber.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.dispatchDate !== null && i.dispatchDate.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.lotNo !== null && i.lotNo.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.sizeOfRoll !== null && i.sizeOfRoll.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.rollTypeName !== null && i.rollTypeName.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()),
    );

    const selectCustomerName = (e: any) => {
        setOrderReceivedDetailsId(e)
        dispatchRollForm.resetForm()
        setAssignCompanyToCone(false)
        setRollDetails([])
        setInputList([])
        setShow(false)
        setIsCheck([])
        setIsDupliteEntry([])
    };

    function view() {
        getDispatchDetailsList()
        setAssignCompanyToCone(false)
        setRollDetails([])
        setShow(true)
        setIsDupliteEntry([])
        setIsCheck([])
        setInputList([...inputList, { dispatchProductDetailsId: 0, typeOfRoll: '', productionEntryCountId: '', ribbonRollStockCountId: '', noOfRoll: '', displayOrder: 1, stockCount: '', orderReceivedQuantityDetailsId: '', sizeOfRibbonData: [], productionEntryData: [] }])
    }

    function add() {
        for (let i = 0; i < count; i++) {
            setCount(count + 1)
        }
        setInputList([...inputList, { dispatchProductDetailsId: 0, typeOfRoll: '', productionEntryCountId: '', ribbonRollStockCountId: '', noOfRoll: '', displayOrder: count, stockCount: '', orderReceivedQuantityDetailsId: '', sizeOfRibbonData: [], productionEntryData: [] }])
    }

    function handleremove(displayOrder: any, item: any, index: any) {
        const list = [...inputList];
        list.splice(index, 1)
        setInputList(list)
        let selectedItem
        rollDetails.forEach((item: any) => {
            if (item['displayOrder'] == displayOrder) {
                selectedItem = item
                rollDetails.splice(rollDetails.indexOf(selectedItem), 1);
            }
        });

        let existingRoll = inputList.filter((data: any) => ((data.productionEntryCountId == item.rollStockId && data.typeOfRoll == item.typeOfRoll) || (data.ribbonRollStockCountId == item.rollStockId) && data.typeOfRoll == item.typeOfRoll) && data.displayOrder != displayOrder)
        setIsDupliteEntry(existingRoll)

        let isCheck: any = list.filter((item: any) => item.orderReceivedQuantityDetailsId == '' || item.noOfRoll == '' || item.noOfRoll == undefined)
        setIsCheck(isCheck)
    }

    function handleInputChange(typeOfRoll: any, orderReceivedQuantityDetailsId: any, lotId: any, rollStockId: any, noOfRoll: any, index: any) {

        const list = [...inputList];

        let existingRoll = inputList.filter((data: any) => ((data.productionEntryCountId == rollStockId?.value && data.typeOfRoll == typeOfRoll) || (data.ribbonRollStockCountId == rollStockId?.value) && data.typeOfRoll == typeOfRoll) && data.displayOrder != list[index]['displayOrder'])
        setIsDupliteEntry(existingRoll)

        if (typeOfRoll == 2) {
            getRibbonRollStockCountList()
            let filData = orderReceivedDetailsData.filter((item: any) => item.orderReceivedDetailsId == orderReceivedDetailsId?.value)
            let ribbonRollDetails = filData[0]?.orderQuantityDetails.filter((item: any) => item.typeOfRoll == 2)
            setRibbonRollData(ribbonRollDetails)
        }
        else if (typeOfRoll == 1) {
            getProductionEntryCountList(list, index)
            let filData = orderReceivedDetailsData.filter((item: any) => item.orderReceivedDetailsId == orderReceivedDetailsId?.value)
            let barcodeStickerDetails = filData[0]?.orderQuantityDetails.filter((item: any) => item.typeOfRoll == 1)
            setBarcodeStickerData(barcodeStickerDetails)
        }

        if (typeOfRoll == 2 && lotId?.value > 0) {
            let selectedData: any = lotDetailsDataForRibbon.find((data: any) => data.lotId == lotId?.value);
            getRibbonRollStockCountListByLotId(selectedData?.lotId, list, index)
        }

        if (typeOfRoll == 2 && sizeOfRibbonData != '' && rollStockId?.value > 0) {
            let earningDetailsData: any = sizeOfRibbonData.filter((data: any) => data.ribbonRollStockCountId == rollStockId?.value)
            list[index]['stockCount'] = earningDetailsData != '' ? earningDetailsData[0].stockCount : null
        }
        if (typeOfRoll == 1 && productionEntryData != '' && rollStockId?.value > 0) {
            let earningDetailsData: any = productionEntryData.filter((data: any) => data.productionEntryCountId == rollStockId?.value)
            list[index]['stockCount'] = earningDetailsData != '' ? earningDetailsData[0].stockCount : null
        }

        list[index]['typeOfRoll'] = typeOfRoll
        list[index]['orderReceivedQuantityDetailsId'] = orderReceivedQuantityDetailsId
        list[index]['productionEntryCountId'] = typeOfRoll == 1 ? rollStockId?.value : 0
        list[index]['ribbonRollStockCountId'] = typeOfRoll == 2 ? rollStockId?.value : 0
        list[index]['noOfRoll'] = noOfRoll
        list[index]['lotId'] = lotId?.value;
        list[index]['lotData'] = lotId
        list[index]['sizeOfRollData'] = rollStockId
        list[index]['existingRoll'] = existingRoll != '' ? true : false
        setInputList(list);
        setRollDetails(list);

        let isCheck: any = list.filter((item: any) => item.orderReceivedQuantityDetailsId == '' || item.noOfRoll == '' || item.noOfRoll == undefined)
        setIsCheck(isCheck)
    }

    function setDispatchDetails() {
        return ({
            licenseKey: getLicenseKey,
            orderReceivedDetailsId: orderReceivedDetailsId?.value,
            rollDetails: rollDetails,
            userAccountId: userAccountId,
        })
    }

    function addDispatchDetailsSubmit() {
        if (isCheck == '') {
            showLoader(true)
            let dispatchPostData = setDispatchDetails()
            if (dispatchRollForm.isValid) {
                addDispatchDetails(dispatchPostData,
                    (response) => {
                        const data = response.data;
                        if (data.success == true) {
                            showLoader(false)
                            setAlertStatus({ message: data.message, type: "success" });
                            setIsOpen(true);
                            closeAndReset()
                        }
                        else if (data.success == false) {
                            showLoader(false)
                            setAlertStatus({ message: data.message, type: "error" });
                            setIsOpen(true);
                        }
                        else {
                            let errorCode = response.data.error[0].error_code;
                            let errorDescription = response.data.error[0].error_description;
                            showLoader(false)
                            setAlertStatus({ message: errorDescription, type: "error" });
                            setIsOpen(true);
                        }
                    }
                    , (error) => {
                        showLoader(false)
                        setAlertStatus({ message: error, type: "error" });
                        setIsOpen(true);
                    }
                )
            } else if (dispatchRollForm.isValid == false) {
                toasts("Please fill all details", "Error");
            }
        } else {
            toasts("Please fill all details", "Error");
        }
    }

    function getDispatchDetailsList() {
        setDataStatus(true)
        getDispatchDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.dispatchDetails;
                    if (data != undefined) {
                        setDataStatus(true)
                        let finalData: any = []

                        for (let i = 0; i < data.length; i++) {

                            let dispatchProductDetails = data[i].dispatchProductDetails

                            for (let j = 0; j < dispatchProductDetails.length; j++) {
                                dispatchProductDetails[j].customerName = data[i].customerName;
                                dispatchProductDetails[j].poNumber = data[i].poNumber;
                                dispatchProductDetails[j].dispatchDate = data[i].dispatchDate;
                                finalData.push(dispatchProductDetails[j])
                            }
                        }
                        for (let i = 0; i < finalData.length; i++) {
                            finalData[i].sno = i + 1;
                        }
                        setDispatchRollData(finalData);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                    setDataStatus(true)
                    setDispatchRollData([])
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getOrderReceivedDetailsList() {
        getOrderReceivedDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.orderReceivedDetails;
                    if (data != undefined) {
                        setOrderReceivedDetailsData(data)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getRibbonRollStockCountList() {
        getRibbonRollStockCount(0, 1,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.ribbonRollStockCount;
                    if (data != undefined) {
                        setLotDetailsDataForRibbon(data)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getRibbonRollStockCountListByLotId(lotId: any, list: any, index: any) {
        getRibbonRollStockCount(lotId, 1,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.ribbonRollStockCount;
                    if (data != undefined) {
                        setSizeOfRibbonData(data)
                        list[index]['sizeOfRibbonData'] = data
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getProductionEntryCountList(list: any, index: any) {
        getProductionEntryCount(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.productionEntryCount;
                    if (data != undefined) {
                        setProductionEntryData(data)
                        list[index]['productionEntryData'] = data
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function closeAndReset() {
        dispatchRollForm.resetForm()
        setOrderReceivedDetailsId('')
        getDispatchDetailsList()
        setAssignCompanyToCone(false)
        setRollDetails([])
        setInputList([])
        setShow(false)
        setIsCheck('')
        setIsDupliteEntry([])
    }

    let sno = 1;

    return (
        <PageWrapper title={`Dispatch`}>
            <Page>
                <Collapse isOpen={isOpenListCard}>
                    <Card stretch data-tour='list' className='d-print-none' tag="form" noValidate onSubmit={dispatchRollForm.handleSubmit}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='PostAdd' iconColor='info'>
                                <CardTitle>
                                    Dispatch Roll
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row g-4'>
                                <div className='col-4'>
                                    <FormGroup id='orderReceivedDetailsId' label='Customer Name' isFloating>
                                        <SearchableSelect
                                            isFloating
                                            ariaLabel={''}
                                            placeholder='Customer Name'
                                            className="form-control"
                                            value={orderReceivedDetailsId}
                                            isValid={dispatchRollForm.isValid}
                                            onBlur={dispatchRollForm.handleBlur}
                                            list={orderReceivedDetailsData.map((data: any) => (
                                                { value: data.orderReceivedDetailsId, label: data.customerName + ' - ' + data.poNumber }
                                            ))}
                                            onChange={selectCustomerName} />
                                    </FormGroup>
                                </div>
                                <div className='col-2'>
                                    <FormGroup className='mt-2'>
                                        <Button
                                            icon="ArrowDownward"
                                            color='primary'
                                            onClick={view}
                                            isDisable={orderReceivedDetailsId?.value > 0 && inputList.length == 0 ? false : true}
                                        > View
                                        </Button>
                                    </FormGroup>
                                </div>

                                {show ?
                                    <div className='col-md-6'>
                                        <FormGroup className='d-flex justify-content-end'>
                                            <Button className="mt-4" icon="Add" color='primary' size={'sm'} onClick={add}>
                                                Add Roll
                                            </Button>
                                        </FormGroup>
                                    </div> : null
                                }

                                {show ? <>
                                    <div className="col-12 mt-4">
                                        {/* <table className='table table-bordered table-hover mt-2'> */}
                                        <table className='table table-modern table-hover '>
                                            <thead>
                                                <tr className='table-primary text-center'>
                                                    <th style={{ width: '16%' }}>Type Of Roll</th>
                                                    <th style={{ width: '20%' }}>Order Item</th>
                                                    <th>Lot No / Production Entry</th>
                                                    <th>Size Of Ribbon / Label Size Description</th>
                                                    <th style={{ width: '11%' }}>Stock Count</th>
                                                    <th style={{ width: '11%' }}>No Of Roll</th>
                                                    {inputList.length > 1 ?
                                                        <th style={{ width: '3%' }}></th> : null
                                                    }
                                                </tr>
                                            </thead>
                                            {inputList.map((x: any, i: any) => (
                                                <tbody key={x.displayOrder}>
                                                    <tr>
                                                        <td>
                                                            <FormGroup id='typeOfRoll' label='Type of Roll' isFloating>
                                                                <Select
                                                                    ariaLabel='Type of Roll'
                                                                    placeholder="Type of Roll"
                                                                    onChange={(e: any) => handleInputChange(e.target.value, x.orderReceivedQuantityDetailsId, x.lotId, x.rollStockId, x.noOfRoll, i)}
                                                                    value={x.typeOfRoll}
                                                                    isValid={dispatchRollForm.isValid}
                                                                    onBlur={dispatchRollForm.handleBlur}
                                                                    list={[{ value: 1, label: 'Barcode Sticker' }, { value: 2, label: 'Ribbon Roll Stock' }]}
                                                                />
                                                            </FormGroup>
                                                        </td>
                                                        <td>
                                                            {x.typeOfRoll == 1 ?
                                                                <FormGroup id='orderReceivedQuantityDetailsId' label='Order Item Description' isFloating>
                                                                    <Select
                                                                        ariaLabel='Type of Roll'
                                                                        placeholder="Type of Roll"
                                                                        onChange={(e: any) => handleInputChange(x.typeOfRoll, e.target.value, x.lotId, x.rollStockId, x.noOfRoll, i)}
                                                                        value={x.orderReceivedQuantityDetailsId}
                                                                        isValid={dispatchRollForm.isValid}
                                                                        onBlur={dispatchRollForm.handleBlur}
                                                                        list={barcodeStickerData.map((data: any) => (
                                                                            { value: data.orderReceivedQuantityDetailsId, label: data.orderItemDescription }
                                                                        ))}
                                                                    />
                                                                </FormGroup> :
                                                                <FormGroup id='orderReceivedQuantityDetailsId' label='Order Item Description' isFloating>
                                                                    <Select
                                                                        ariaLabel='Type of Roll'
                                                                        placeholder="Type of Roll"
                                                                        onChange={(e: any) => handleInputChange(x.typeOfRoll, e.target.value, x.lotId, x.rollStockId, x.noOfRoll, i)}
                                                                        value={x.orderReceivedQuantityDetailsId}
                                                                        isValid={dispatchRollForm.isValid}
                                                                        onBlur={dispatchRollForm.handleBlur}
                                                                        list={ribbonRollData.map((data: any) => (
                                                                            { value: data.orderReceivedQuantityDetailsId, label: data.orderItemDescription }
                                                                        ))}
                                                                    />
                                                                </FormGroup>
                                                            }
                                                        </td>
                                                        <td>
                                                            {x.typeOfRoll == 2 ?
                                                                <FormGroup id='lotId' label='Lot No' isFloating>
                                                                    <SearchableSelect
                                                                        isFloating
                                                                        ariaLabel={''}
                                                                        placeholder='Lot No'
                                                                        className="form-control"
                                                                        value={x.lotData}
                                                                        isValid={dispatchRollForm.isValid}
                                                                        onBlur={dispatchRollForm.handleBlur}
                                                                        disabled={x.typeOfRoll > 0 ? false : true}
                                                                        list={lotDetailsDataForRibbon.map((data: any) => (
                                                                            { value: data.lotId, label: data.lotNo }
                                                                        ))}
                                                                        onChange={(e: any) => handleInputChange(x.typeOfRoll, x.orderReceivedQuantityDetailsId, e, x.sizeOfRollData, x.noOfCone, i)} />
                                                                </FormGroup> :
                                                                <FormGroup id='lotId' label='Production Entry' isFloating>
                                                                    {/* <p className='text-center mt-3 text-primary'>Production Entry</p> */}
                                                                    <Input
                                                                        // value={'Production Entry'}
                                                                        disabled
                                                                        placeholder="Enter No of Roll" />
                                                                </FormGroup>
                                                            }
                                                        </td>
                                                        <td>
                                                            {x.typeOfRoll == 2 ?
                                                                <FormGroup id='ribbonRollStockCountId' label='Size Of Ribbon' isFloating>
                                                                    <SearchableSelect
                                                                        isFloating
                                                                        ariaLabel={''}
                                                                        placeholder='Size Of Ribbon'
                                                                        className="form-control"
                                                                        value={x.sizeOfRollData}
                                                                        onBlur={dispatchRollForm.handleBlur}
                                                                        list={x.sizeOfRibbonData.map((data: any) => (
                                                                            { value: data.ribbonRollStockCountId, label: data.sizeOfRibbon }
                                                                        ))}
                                                                        onChange={(e: any) => handleInputChange(x.typeOfRoll, x.orderReceivedQuantityDetailsId, x.lotData, e, x.noOfRoll, i)} />
                                                                </FormGroup> :
                                                                <FormGroup id='productionEntryCountId' label='Label Size Description' isFloating>
                                                                    <SearchableSelect
                                                                        isFloating
                                                                        ariaLabel={''}
                                                                        placeholder='Label Size Description'
                                                                        className="form-control"
                                                                        value={x.sizeOfRollData}
                                                                        onBlur={dispatchRollForm.handleBlur}
                                                                        list={x.productionEntryData.map((data: any) => (
                                                                            { value: data.productionEntryCountId, label: data.labelSizeDescription }
                                                                        ))}
                                                                        onChange={(e: any) => handleInputChange(x.typeOfRoll, x.orderReceivedQuantityDetailsId, x.lotData, e, x.noOfRoll, i)} />
                                                                </FormGroup>
                                                            }
                                                        </td>
                                                        <td>
                                                            <FormGroup id='stockCount' label='Stock Count' isFloating>
                                                                <Input
                                                                    value={x.stockCount}
                                                                    disabled
                                                                />
                                                            </FormGroup>
                                                        </td>
                                                        <td>
                                                            <FormGroup id='noOfRoll' label='No of Roll' isFloating>
                                                                <Input
                                                                    onChange={(e: any) => handleInputChange(x.typeOfRoll, x.orderReceivedQuantityDetailsId, x.lotData, x.sizeOfRollData, e.target.value, i)}
                                                                    value={x.noOfRoll}
                                                                    isValid={dispatchRollForm.isValid}
                                                                    onBlur={dispatchRollForm.handleBlur}
                                                                    type='number'
                                                                    placeholder="Enter No of Roll" />
                                                            </FormGroup>
                                                        </td>
                                                        {inputList.length > 1 ?
                                                            <td>
                                                                <Button className='mt-2' color='danger' isLight icon='Close'
                                                                    onClick={() => handleremove(x.displayOrder, x, i)}
                                                                />
                                                            </td> : null
                                                        }
                                                    </tr>
                                                    {x.existingRoll ?
                                                        <tr className='text-center'><td className='text-danger' colSpan={7}>Duplite Entry</td></tr> : null
                                                    }
                                                </tbody>
                                            ))}
                                        </table>
                                    </div>
                                    <div className='col-md-12'>
                                        <FormGroup className='d-flex justify-content-center'>
                                            <Button icon="Check" color='primary' isDisable={isCheck != '' || isDupliteEntry != "" || rollDetails.length == 0 ? true : false} onClick={dispatchRollForm.handleSubmit}>
                                                Submit
                                            </Button>
                                        </FormGroup>
                                    </div></> : null
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                {dispatchRollData != '' ?
                    <Card stretch data-tour='list'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-7">
                                <CardTitle tag='div' className='h5'>Dispatch List</CardTitle>
                            </CardLabel>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibileForm.handleChange} value={columnVisibileForm.values.searchInput} />
                            </ButtonGroup>
                        </CardHeader>
                        <CardBody isScrollable>
                            {!dataStatus ?
                                <TableLoader /> :
                                <table className='table table-modern table-hover'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')} className='cursor-pointer text-decoration-underline'>S.No <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' /></th>
                                            <th scope='col' onClick={() => requestSort('rollTypeName')} className='cursor-pointer text-decoration-underline'>Type Of Roll <Icon size='lg' className={getClassNamesFor('rollTypeName')} icon='FilterList' /></th>
                                            <th scope='col' onClick={() => requestSort('lotNo')} className='cursor-pointer text-decoration-underline'>Lot No /<br /> Production Entry <Icon size='lg' className={getClassNamesFor('lotNo')} icon='FilterList' /></th>
                                            <th scope='col' onClick={() => requestSort('sizeOfRoll')} className='cursor-pointer text-decoration-underline'>Size Of Roll <Icon size='lg' className={getClassNamesFor('sizeOfRoll')} icon='FilterList' /></th>
                                            <th scope='col' onClick={() => requestSort('customerName')} className='cursor-pointer text-decoration-underline'>Customer Name <Icon size='lg' className={getClassNamesFor('customerName')} icon='FilterList' /></th>
                                            <th scope='col' onClick={() => requestSort('dispatchDate')} className='cursor-pointer text-decoration-underline'>Date <Icon size='lg' className={getClassNamesFor('dispatchDate')} icon='FilterList' /></th>
                                            <th scope='col' onClick={() => requestSort('noOfRoll')} className='cursor-pointer text-decoration-underline'>No Of Roll <Icon size='lg' className={getClassNamesFor('noOfRoll')} icon='FilterList' /></th>
                                            <th scope='col' onClick={() => requestSort('scannedCount')} className='cursor-pointer text-decoration-underline'>No Of Roll Scanned <Icon size='lg' className={getClassNamesFor('scannedCount')} icon='FilterList' /></th>
                                        </tr>
                                    </thead>
                                    {dispatchRollData != "" ? <>{filteredData.map((item: any) => (<tbody key={item.dispatchProductDetailsId}><tr><td>{item.sno}</td><td>{item.rollTypeName}</td><td>{item.lotNo}</td><td>{item.sizeOfRoll}</td><td>{item.customerName + ' - ' + item.poNumber}</td><td>{item.dispatchDate}</td><td>{item.noOfRoll}</td><td>{item.scannedCount}</td></tr></tbody>))}</> : <NoDataMsg columnsCount={10} msg={noDataMsg} />}
                                </table>
                            }
                        </CardBody>
                        <PaginationButtons className="d-print-none" data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                    </Card> : null
                }
                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </Page>
        </PageWrapper >
    )
}

export default DispatchRoll