import React, { useContext, useEffect, useState } from 'react'
import PageWrapper from '../../layout/PageWrapper/PageWrapper'
import Page from '../../layout/Page/Page'
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card'
import Input from '../bootstrap/forms/Input'
import Button, { ButtonGroup } from '../bootstrap/Button'
import { useFormik } from 'formik'
import Icon from '../icon/Icon'
import AuthContext from '../../contexts/authContext'
import { TableLoader, showLoader } from '../../services/loader.services'
import NoDataMsg from '../../common/components/NoDataMsg'
import useSortableData from '../../hooks/useSortableData'
import PaginationButtons, { dataPagination } from '../PaginationButtons'
import { addJumboRollStockDispatch, getJumboRollStock, getJumboRollStockDispatch, getScannedJumboRollStock } from '../../services/stockService'
import { toasts } from '../../services/toaste.service'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../bootstrap/OffCanvas'
import FormGroup from '../bootstrap/forms/FormGroup'
import Select from '../bootstrap/forms/Select'
import AlertService from '../../services/alert.service'
import { getLicenseKey } from '../../services/application.settings'

const DispatchJumboRoll = () => {

    useEffect(() => {
        getLotDetailsList()
        getJumboRollStockDispatchList()
    }, [])

    const jumboRollDispatchForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            lotId: '',
            jumboRollStockId: '',
            companyName: '',
            noOfCone: '',
            lotNo: ''
        },
        validate: (values: any) => {
            const errors: {
                jumboRollStockId?: string;
                companyName?: string;
                noOfCone?: string;
            } = {};
            if (!values.jumboRollStockId) {
                errors.jumboRollStockId = 'Required';
            }
            if (!values.companyName) {
                errors.companyName = 'Required';
            }
            if (!values.noOfCone) {
                errors.noOfCone = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { addJumboRollStockDispatchSubmit() },
    });

    const columnVisibileForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [orderReceivedDetailsData, setOrderReceivedDetailsData] = useState<any>([])
    const [noDataMsg, setNoDataMsg] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(orderReceivedDetailsData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [dataStatus, setDataStatus] = useState(false)
    const [isOpen, setIsOpen] = useState(false);
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [lotNo, setLotNo] = useState<any>('')
    const [receivedSizeData, setReceivedSizeData] = useState<any>([])
    const [assignCompanyToCone, setAssignCompanyToCone] = useState(false)
    const [lotDetailsData, setLotDetailsData] = useState([])

    // Filter
    const filteredData = onCurrentPageData.filter(
        (i) =>
            i.sno !== null && i.sno.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.lotNo !== null && i.lotNo.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.receivedSize !== null && i.receivedSize.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.totalScannedCount !== null && i.totalScannedCount.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()),
    );

    const selectLotNo = (e: any) => {
        let selectedData: any = lotDetailsData.find((data: any) => data.lotNo == e.target.value);
        setLotNo(selectedData.lotNo)
        getJumboRollStockList(selectedData.lotId)
    };

    function setDispatchDetails() {
        return ({
            licenseKey: getLicenseKey,
            jumboRollStockId: jumboRollDispatchForm.values.jumboRollStockId,
            companyName: jumboRollDispatchForm.values.companyName,
            noOfCone: jumboRollDispatchForm.values.noOfCone,
            userAccountId: userAccountId,

        })
    }

    function addJumboRollStockDispatchSubmit() {
        showLoader(true)
        let dispatchPostData = setDispatchDetails()
        if (jumboRollDispatchForm.isValid) {
            addJumboRollStockDispatch(dispatchPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset()
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (jumboRollDispatchForm.isValid == false) {
            toasts("Please fill all details", "Error");
        }
    }

    function getJumboRollStockDispatchList() {
        getJumboRollStockDispatch(0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.jumboRollStockDispatch;
                    if (data != undefined) {
                        setOrderReceivedDetailsData(data)
                        setDataStatus(true)
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                    setDataStatus(true)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getLotDetailsList() {
        getJumboRollStock(0, 1, 0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.jumboRollStock;
                    if (data != undefined) {
                        setLotDetailsData(data)
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getJumboRollStockList(lotId: any) {
        getJumboRollStock(lotId, 1, 0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.jumboRollStock;
                    if (data != undefined) {
                        setReceivedSizeData(data)
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function closeAndReset() {
        jumboRollDispatchForm.resetForm()
        getJumboRollStockDispatchList()
        setLotNo('')
        setAssignCompanyToCone(false)
    }

    return (
        <PageWrapper title={`Dispatch`}>
            <Page>
                <Card stretch data-tour='list'>
                    <CardHeader borderSize={1}>
                        <CardLabel icon='List' iconColor='info' className="col-lg-7">
                            <CardTitle tag='div' className='h5'>Dispatch - Jumbo Roll Stock</CardTitle>
                        </CardLabel>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                            <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                            <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibileForm.handleChange} value={columnVisibileForm.values.searchInput} />
                        </ButtonGroup>

                        <CardActions className="d-print-none">
                            <Button color='primary' icon='Add' isLight onClick={() => setAssignCompanyToCone(true)}>
                                Assign
                            </Button>
                        </CardActions>
                    </CardHeader>
                    <CardBody>
                        {!dataStatus ?
                            <TableLoader /> :
                            <table className='table table-modern table-hover'>
                                <thead>
                                    <tr>
                                        <th style={{ width: '18%' }} scope='col' onClick={() => requestSort('sno')} className='cursor-pointer text-decoration-underline'>S.No <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' /></th>
                                        <th style={{ width: '18%' }} scope='col' onClick={() => requestSort('lotNo')} className='cursor-pointer text-decoration-underline'>Lot No <Icon size='lg' className={getClassNamesFor('lotNo')} icon='FilterList' /></th>
                                        <th style={{ width: '18%' }} scope='col' onClick={() => requestSort('receivedSize')} className='cursor-pointer text-decoration-underline'>Received Size <Icon size='lg' className={getClassNamesFor('receivedSize')} icon='FilterList' /></th>
                                        <th style={{ width: '18%' }} scope='col' onClick={() => requestSort('companyName')} className='cursor-pointer text-decoration-underline'>Company Name <Icon size='lg' className={getClassNamesFor('companyName')} icon='FilterList' /></th>
                                        <th style={{ width: '18%' }} scope='col' onClick={() => requestSort('noOfCone')} className='cursor-pointer text-decoration-underline'>No of Cone <Icon size='lg' className={getClassNamesFor('noOfCone')} icon='FilterList' /></th>
                                    </tr>
                                </thead>
                                {orderReceivedDetailsData != "" ? <>{filteredData.map((item: any) => (<tbody key={item.jumboRollStockId}><tr><td>{item.sno}</td><td>{item.lotNo}</td><td>{item.receivedSize}</td><td>{item.companyName}</td><td>{item.noOfCone}</td></tr></tbody>))}</> : <NoDataMsg columnsCount={10} msg={noDataMsg} />}
                            </table>
                        }
                    </CardBody>
                    <PaginationButtons className="d-print-none" data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                </Card>

                <OffCanvas setOpen={setAssignCompanyToCone} isOpen={assignCompanyToCone} titleId='addProductOffcanvas' isBodyScroll placement='end' tag="form" noValidate onSubmit={jumboRollDispatchForm.handleSubmit} isNotClose>
                    <OffCanvasHeader setOpen={setAssignCompanyToCone}>
                        <OffCanvasTitle id='addProductOffcanvas'>Dispath - Assign Cone</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <div className='row g-4'>
                            <div className='col-12'>
                                <FormGroup id='lotId' label='Lot No'>
                                    <Select
                                        ariaLabel='Lot No'
                                        placeholder="Select Lot No"
                                        onChange={selectLotNo}
                                        value={lotNo}
                                        isValid={jumboRollDispatchForm.isValid}
                                        onBlur={jumboRollDispatchForm.handleBlur}
                                        isTouched={jumboRollDispatchForm.touched.lotNo}
                                        invalidFeedback={jumboRollDispatchForm.errors.lotNo}
                                        list={lotDetailsData.map((data: any) => (
                                            { value: data.lotNo, text: data.lotNo }
                                        ))}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='jumboRollStockId' label='Received Size'>
                                    <Select
                                        ariaLabel='Received Size'
                                        placeholder="Received Size"
                                        onChange={jumboRollDispatchForm.handleChange}
                                        value={jumboRollDispatchForm.values.jumboRollStockId}
                                        isValid={jumboRollDispatchForm.isValid}
                                        onBlur={jumboRollDispatchForm.handleBlur}
                                        isTouched={jumboRollDispatchForm.touched.jumboRollStockId}
                                        invalidFeedback={jumboRollDispatchForm.errors.jumboRollStockId}
                                        list={receivedSizeData.map((data: any) => (
                                            { value: data.jumboRollStockId, text: data.receivedSize }
                                        ))}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='companyName' label='Company Name'>
                                    <Input onChange={jumboRollDispatchForm.handleChange} value={jumboRollDispatchForm.values.companyName}
                                        isValid={jumboRollDispatchForm.isValid}
                                        onBlur={jumboRollDispatchForm.handleBlur}
                                        isTouched={jumboRollDispatchForm.touched.companyName}
                                        invalidFeedback={jumboRollDispatchForm.errors.companyName}
                                        placeholder="Enter Company Name" />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='noOfCone' label='No of Cone'>
                                    <Input onChange={jumboRollDispatchForm.handleChange} value={jumboRollDispatchForm.values.noOfCone}
                                        isValid={jumboRollDispatchForm.isValid}
                                        onBlur={jumboRollDispatchForm.handleBlur}
                                        isTouched={jumboRollDispatchForm.touched.noOfCone}
                                        invalidFeedback={jumboRollDispatchForm.errors.noOfCone}
                                        type='number'
                                        placeholder="Enter No of Cone" />
                                </FormGroup>
                            </div>
                        </div>
                    </OffCanvasBody>
                    <div className='row m-0'>
                        <div className='col-12 p-3'>
                            <Button color='info' className='w-100' type="submit" icon="Save" isDisable={!jumboRollDispatchForm.isValid && !!jumboRollDispatchForm.submitCount}>
                                Save
                            </Button>
                        </div>
                    </div>
                </OffCanvas>
                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </Page>
        </PageWrapper >
    )
}

export default DispatchJumboRoll