import React, { useContext, useEffect, useState } from 'react'
import PageWrapper from '../../layout/PageWrapper/PageWrapper'
import Page from '../../layout/Page/Page'
import Collapse from '../bootstrap/Collapse'
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card'
import FormGroup from '../bootstrap/forms/FormGroup'
import Input from '../bootstrap/forms/Input'
import Button, { ButtonGroup } from '../bootstrap/Button'
import { useFormik } from 'formik'
import Select from '../bootstrap/forms/Select'
import Icon from '../icon/Icon'
import { getLicenseKey } from '../../services/application.settings'
import AuthContext from '../../contexts/authContext'
import { showLoader } from '../../services/loader.services'
import AlertService from '../../services/alert.service'
import NoDataMsg from '../../common/components/NoDataMsg'
import InputGroup from '../bootstrap/forms/InputGroup'
import useSortableData from '../../hooks/useSortableData'
import PaginationButtons, { dataPagination } from '../PaginationButtons'
import { addJumboRollStock, getJumboRollStock } from '../../services/stockService'
import { toasts } from '../../services/toaste.service'
import { getCurrentDateAndTime, getMachineDetails, getSubModuleType } from '../../services/common.service'
import { addProductionEntry, deleteProductionEntry, getAssignOrderList, getProductionEntryCount, getProductionEntryList, updateProductionEntry } from '../../services/orderService'
import SearchableSelect from '../../common/components/SearchableSelect'
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../bootstrap/Modal'
import DeleteComponents from '../../common/components/DeleteComponents'
import Popovers from '../bootstrap/Popovers'

const ProductionEntry = () => {

    useEffect(() => {
        getProductionEntryDetails()
        getAssignOrderDetailsList()
        getMachineDetailsList()
        getProductionEntryCountList()
    }, [])

    const productionEntryForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            machineDetailsId: '',
            labelSizeDescription: '',
            noOfRoll: '',
            productionEntryDate: getCurrentDateAndTime('date'),
            operaterName: ''
        },
        validate: (values: any) => {
            const errors: {
                machineDetailsId?: string;
                operaterName?: string;
                labelSizeDescription?: string;
                noOfRoll?: string;
            } = {};
            if (!machineDetailsId?.value) {
                errors.machineDetailsId = 'Required';
            }
            if (!values.operaterName) {
                errors.operaterName = 'Required';
            }
            if (isVisibleOnLabelSize == true && !labelSizeDescription?.value || isVisibleOnLabelSize == false && !values.labelSizeDescription) {
                errors.labelSizeDescription = 'Required';
            }
            if (!values.noOfRoll) {
                errors.noOfRoll = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { addProductionEntrySubmit() },
    });

    const productionEntryEditForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            machineDetailsId: '',
            labelSizeDescription: '',
            noOfRoll: '',
            productionEntryDate: getCurrentDateAndTime('date'),
            operaterName: ''
        },
        validate: (values: any) => {
            const errors: {
                machineDetailsId?: string;
                operaterName?: string;
                labelSizeDescription?: string;
                noOfRoll?: string;
            } = {};
            if (!machineDetailsIdForEdit?.value) {
                errors.machineDetailsId = 'Required';
            }
            if (!values.operaterName) {
                errors.operaterName = 'Required';
            }
            if (isVisibleOnLabelSize == true && !labelSizeDescriptionForEdit?.value || isVisibleOnLabelSize == false && !values.labelSizeDescription) {
                errors.labelSizeDescription = 'Required';
            }
            if (!values.noOfRoll) {
                errors.noOfRoll = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { updateProductionEntrySubmit() },
    });

    const columnVisibileForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [productionEntryData, setProductionEntryData] = useState<any>([])
    const [noDataMsg, setNoDataMsg] = useState('')

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(productionEntryData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    // Filter
    const filteredData = onCurrentPageData.filter(
        (i) =>
            i.sno !== null && i.sno.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.machineName !== null && i.machineName.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.operaterName !== null && i.operaterName.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.labelSizeDescription !== null && i.labelSizeDescription.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.productionEntryDate !== null && i.productionEntryDate.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.noOfRoll !== null && i.noOfRoll.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()),
    );

    function productionEntryDetails() {
        return ({
            licenseKey: getLicenseKey,
            assignOrderId: 0,
            machineDetailsId: machineDetailsId?.value,
            operaterName: productionEntryForm.values.operaterName,
            labelSizeDescription: isVisibleOnLabelSize ? labelSizeDescription?.value : productionEntryForm.values.labelSizeDescription,
            noOfRoll: productionEntryForm.values.noOfRoll,
            productionEntryDate: productionEntryForm.values.productionEntryDate,
            userAccountId: userAccountId,
        })
    }

    function productionEntryEditDetails() {
        return ({
            licenseKey: getLicenseKey,
            productionEntryId: productionEntryId,
            machineDetailsId: machineDetailsIdForEdit?.value,
            operaterName: productionEntryEditForm.values.operaterName,
            labelSizeDescription: isVisibleOnLabelSize ? labelSizeDescriptionForEdit?.value : productionEntryEditForm.values.labelSizeDescription,
            noOfRoll: productionEntryEditForm.values.noOfRoll,
            productionEntryDate: productionEntryEditForm.values.productionEntryDate,
            userAccountId: userAccountId,
        })
    }

    function addProductionEntrySubmit() {
        showLoader(true)
        let productionEntryPostData = productionEntryDetails()
        if (productionEntryForm.isValid) {
            addProductionEntry(productionEntryPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset()
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (productionEntryForm.isValid == false) {
            toasts("Please fill all details", "Error");
        }
    }

    function updateProductionEntrySubmit() {
        showLoader(true)
        let productionEntryPostData = productionEntryEditDetails()
        if (productionEntryForm.isValid) {
            updateProductionEntry(productionEntryPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset()
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (productionEntryForm.isValid == false) {
            toasts("Please fill all details", "Error");
        }
    }

    function deleteProductionEntrySubmit() {
        showLoader(true)
        let productionEntryPostData = productionEntryEditDetails()
        deleteProductionEntry(productionEntryPostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "success" });
                    setIsOpen(true);
                    // setProductionEntryData([])
                    closeAndReset()
                }
                else if (data.success == false) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "error" });
                    setIsOpen(true);
                }
                else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    showLoader(false)
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }
            , (error) => {
                showLoader(false)
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
    }

    function getProductionEntryDetails() {
        getProductionEntryList(0, 0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.productionEntryList;
                    if (data != undefined) {
                        setProductionEntryData(data)
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setProductionEntryData([])
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const [assignOrderDetailsData, setAssignOrderDetailsData] = useState([])

    function getAssignOrderDetailsList() {
        getAssignOrderList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.assignOrderList;
                    if (data != undefined) {
                        setAssignOrderDetailsData(data)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function onPrint(i: any) {
        if (i != undefined) {
            let selectedData: any = i
            window.open(selectedData.pdfPath, '_blank');
        }
    }

    function closeAndReset() {
        productionEntryForm.resetForm()
        productionEntryEditForm.resetForm()
        getProductionEntryDetails()
        getProductionEntryCountList()
        setAssignOrderId([])
        setMachineDetailsId([])
        setIsOpenEditModal(false)
        setLabelSizeDescription([])
        setIsDeleteOpen(false)
    }

    const [machineDetailsId, setMachineDetailsId] = useState<any>([])
    const [assignOrderId, setAssignOrderId] = useState<any>([])

    function selectMachineName(e: any) {
        setAssignOrderId(e)
        setMachineDetailsId(e)
    }

    const [machineDetailsData, setMachineDetailsData] = useState([])

    function getMachineDetailsList() {
        getMachineDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.machineDetails;
                    if (data != undefined) {
                        setMachineDetailsData(data)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const [productionEntryCountData, setProductionEntryCountData] = useState([])
    const [labelSizeDescription, setLabelSizeDescription] = useState<any>([])
    const [isVisibleOnLabelSize, setIsVisibleOnLabelSize] = useState<any>(false)

    function selectLabelSizeDescription(e: any) {
        setLabelSizeDescription(e)
    }

    function getProductionEntryCountList() {
        getProductionEntryCount(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.productionEntryCount;
                    if (data != undefined) {
                        setProductionEntryCountData(data)
                        setIsVisibleOnLabelSize(true)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                    setIsVisibleOnLabelSize(false)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const [machineDetailsIdForEdit, setMachineDetailsIdForEdit] = useState<any>([])
    const [labelSizeDescriptionForEdit, setLabelSizeDescriptionForEdit] = useState<any>([])
    const [isOpenEditModal, setIsOpenEditModal] = useState(false)
    const [productionEntryId, setProductionEntryId] = useState<any>('')

    function onActivate(i: any) {
        if (i != undefined) {
            let selectedData = i
            setIsOpenEditModal(true)
            setMachineDetailsIdForEdit({ value: selectedData.machineDetailsId, label: selectedData.machineName })
            setLabelSizeDescriptionForEdit({ value: selectedData.labelSizeDescription, label: selectedData.labelSizeDescription })
            setProductionEntryId(selectedData.productionEntryId)
            productionEntryEditForm.setValues({
                machineDetailsId: selectedData.machineDetailsId,
                labelSizeDescription: selectedData.labelSizeDescription,
                noOfRoll: selectedData.noOfRoll,
                productionEntryDate: selectedData.productionEntryDate,
                operaterName: selectedData.operaterName
            });
        }
    }

    const [isDeleteOpen, setIsDeleteOpen] = useState(false)

    return (
        <PageWrapper title={`Generate QR`}>
            <Page>
                <Collapse isOpen={isOpenListCard}>
                    <Card stretch data-tour='list' className='d-print-none' tag="form" noValidate onSubmit={productionEntryForm.handleSubmit}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='WifiProtectedSetup' iconColor='info'>
                                <CardTitle>
                                    Production Entry
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row g-4'>
                                <div className='col-3'>
                                    <FormGroup id='machineDetailsId' label='Machine Name' isFloating>
                                        <SearchableSelect
                                            ariaLabel={''}
                                            placeholder='Machine Name'
                                            isFloating
                                            className="form-control"
                                            value={machineDetailsId}
                                            onBlur={productionEntryForm.handleBlur}
                                            isTouched={productionEntryForm.touched.machineDetailsId}
                                            invalidFeedback={productionEntryForm.errors.machineDetailsId}
                                            list={machineDetailsData.map((data: any) => (
                                                { value: data.machineDetailsId, label: data.machineName }
                                            ))}
                                            onChange={(e: any) => selectMachineName(e)} />
                                    </FormGroup>
                                </div>
                                <div className='col-3'>
                                    <FormGroup id='operaterName' label='Operater Name' isFloating>
                                        <Input onChange={productionEntryForm.handleChange}
                                            value={productionEntryForm.values.operaterName}
                                            onBlur={productionEntryForm.handleBlur}
                                            isTouched={productionEntryForm.touched.operaterName}
                                            invalidFeedback={productionEntryForm.errors.operaterName}
                                            placeholder="Operater Name" />
                                    </FormGroup>
                                </div>
                                <div className='col-3'>
                                    <InputGroup>
                                        {isVisibleOnLabelSize ?
                                            <FormGroup id='labelSizeDescription' label='Label Size Description' isFloating>
                                                <SearchableSelect
                                                    ariaLabel={''}
                                                    placeholder='Label Size Description'
                                                    isFloating
                                                    className="form-control"
                                                    value={labelSizeDescription}
                                                    onBlur={productionEntryForm.handleBlur}
                                                    isTouched={productionEntryForm.touched.labelSizeDescription}
                                                    invalidFeedback={productionEntryForm.errors.labelSizeDescription}
                                                    list={productionEntryCountData.map((data: any) => (
                                                        { value: data.labelSizeDescription, label: data.labelSizeDescription }
                                                    ))}
                                                    onChange={(e: any) => selectLabelSizeDescription(e)} />
                                            </FormGroup> :
                                            <FormGroup id='labelSizeDescription' label='Label Size Description' isFloating>
                                                <Input
                                                    onChange={productionEntryForm.handleChange}
                                                    value={productionEntryForm.values.labelSizeDescription}
                                                    isValid={productionEntryForm.isValid}
                                                    onBlur={productionEntryForm.handleBlur}
                                                    isTouched={productionEntryForm.touched.labelSizeDescription}
                                                    invalidFeedback={productionEntryForm.errors.labelSizeDescription}
                                                    placeholder="Enter Label Size Description" />
                                            </FormGroup>
                                        }
                                        <Button isOutline color='primary' icon='Add' style={{ height: '46px' }} onClick={() => setIsVisibleOnLabelSize(!isVisibleOnLabelSize)}></Button>
                                    </InputGroup>
                                </div>
                                <div className='col-3'>
                                    <FormGroup id='noOfRoll' label='No Of Roll' isFloating>
                                        <Input onChange={productionEntryForm.handleChange} value={productionEntryForm.values.noOfRoll}
                                            isValid={productionEntryForm.isValid}
                                            onBlur={productionEntryForm.handleBlur}
                                            isTouched={productionEntryForm.touched.noOfRoll}
                                            invalidFeedback={productionEntryForm.errors.noOfRoll}
                                            type='number'
                                            placeholder="Enter No Of Roll" />
                                    </FormGroup>
                                </div>
                                <div className='col-3'>
                                    <FormGroup id='productionEntryDate' label='Production Entry Date' isFloating>
                                        <Input onChange={productionEntryForm.handleChange}
                                            value={productionEntryForm.values.productionEntryDate}
                                            isValid={productionEntryForm.isValid}
                                            onBlur={productionEntryForm.handleBlur}
                                            isTouched={productionEntryForm.touched.productionEntryDate}
                                            invalidFeedback={productionEntryForm.errors.productionEntryDate}
                                            type='date'
                                            placeholder="Select Production Entry Date" />
                                    </FormGroup>
                                </div>
                                <div className='col-md-2'>
                                    <Button className="mt-2" icon="WifiProtectedSetup" color='primary' onClick={productionEntryForm.handleSubmit}>
                                        Generate
                                    </Button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                {productionEntryData != '' ?
                    <Card stretch data-tour='list' className='d-print-none'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle>
                                    Production Entry List
                                </CardTitle>
                            </CardLabel>
                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibileForm.handleChange} value={columnVisibileForm.values.searchInput} />
                            </ButtonGroup>
                        </CardHeader>
                        <CardBody isScrollable>
                            <table className='table table-modern table-hover'>
                                <thead>
                                    <tr>
                                        <th scope='col' onClick={() => requestSort('sno')} className='cursor-pointer text-decoration-underline'>S.No <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('machineName')} className='cursor-pointer text-decoration-underline'>Machine Name <Icon size='lg' className={getClassNamesFor('machineName')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('operaterName')} className='cursor-pointer text-decoration-underline'>Operater Name <Icon size='lg' className={getClassNamesFor('operaterName')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('labelSizeDescription')} className='cursor-pointer text-decoration-underline'>Label Size Description <Icon size='lg' className={getClassNamesFor('labelSizeDescription')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('noOfRoll')} className='cursor-pointer text-decoration-underline'>No Of Roll <Icon size='lg' className={getClassNamesFor('noOfRoll')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('productionEntryDate')} className='cursor-pointer text-decoration-underline'>Date <Icon size='lg' className={getClassNamesFor('productionEntryDate')} icon='FilterList' /></th>
                                        <th><span style={{ marginLeft: '30px' }}>Action</span></th>
                                    </tr>
                                </thead>
                                {filteredData.map((item: any) => (
                                    <tbody key={item.productionEntryId}>
                                        <tr>
                                            <td>{item.sno}</td>
                                            <td>{item.machineName}</td>
                                            <td>{item.operaterName}</td>
                                            <td>{item.labelSizeDescription}</td>
                                            <td>{item.noOfRoll}</td>
                                            <td>{item.productionEntryDate}</td>
                                            <td className="d-print-none"><Popovers desc='Edit' trigger='hover'><Button color='primary' isLight icon='Edit' aria-label='Edit' onClick={() => onActivate(item)}></Button></Popovers> <Popovers desc='Delete' trigger='hover'><Button color='danger' isLight icon='Delete' aria-label='Delete' onClick={() => { setIsDeleteOpen(true); setProductionEntryId(item.productionEntryId) }}></Button></Popovers> <Popovers desc='Download' trigger='hover'><Button color='success' isLight icon='Download' aria-label='Download' onClick={() => onPrint(item)}></Button></Popovers></td>
                                        </tr>
                                    </tbody>
                                ))}
                            </table>
                        </CardBody>
                        <PaginationButtons className="d-print-none" data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                    </Card> : null
                }

                <Modal
                    setIsOpen={closeAndReset}
                    isOpen={isOpenEditModal}
                    titleId='edit'
                    isStaticBackdrop
                    isCentered
                    size='lg'>
                    <ModalHeader setIsOpen={closeAndReset}>
                        <ModalTitle id='examplemailcontent'>
                            <div className="h4">Update Order Quantity Details
                            </div>
                        </ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <div className='row g-4'>
                            <div className='col-6'>
                                <FormGroup id='machineDetailsId' label='Machine Name' isFloating>
                                    <SearchableSelect
                                        ariaLabel={''}
                                        placeholder='Machine Name'
                                        isFloating
                                        className="form-control"
                                        value={machineDetailsIdForEdit}
                                        onBlur={productionEntryEditForm.handleBlur}
                                        isTouched={productionEntryEditForm.touched.machineDetailsId}
                                        invalidFeedback={productionEntryEditForm.errors.machineDetailsId}
                                        list={machineDetailsData.map((data: any) => (
                                            { value: data.machineDetailsId, label: data.machineName }
                                        ))}
                                        onChange={(e: any) => selectMachineName(e)} />
                                </FormGroup>
                            </div>
                            <div className='col-6'>
                                <FormGroup id='operaterName' label='Operater Name' isFloating>
                                    <Input onChange={productionEntryEditForm.handleChange}
                                        value={productionEntryEditForm.values.operaterName}
                                        onBlur={productionEntryEditForm.handleBlur}
                                        isTouched={productionEntryEditForm.touched.operaterName}
                                        invalidFeedback={productionEntryEditForm.errors.operaterName}
                                        placeholder="Operater Name" />
                                </FormGroup>
                            </div>
                            <div className='col-6'>
                                <InputGroup>
                                    {isVisibleOnLabelSize ?
                                        <FormGroup id='labelSizeDescription' label='Label Size Description' isFloating>
                                            <SearchableSelect
                                                ariaLabel={''}
                                                placeholder='Label Size Description'
                                                isFloating
                                                className="form-control"
                                                value={labelSizeDescriptionForEdit}
                                                onBlur={productionEntryEditForm.handleBlur}
                                                isTouched={productionEntryEditForm.touched.labelSizeDescription}
                                                invalidFeedback={productionEntryEditForm.errors.labelSizeDescription}
                                                list={productionEntryCountData.map((data: any) => (
                                                    { value: data.labelSizeDescription, label: data.labelSizeDescription }
                                                ))}
                                                onChange={(e: any) => selectLabelSizeDescription(e)} />
                                        </FormGroup> :
                                        <FormGroup id='labelSizeDescription' label='Label Size Description' isFloating>
                                            <Input
                                                onChange={productionEntryEditForm.handleChange}
                                                value={productionEntryEditForm.values.labelSizeDescription}
                                                isValid={productionEntryEditForm.isValid}
                                                onBlur={productionEntryEditForm.handleBlur}
                                                isTouched={productionEntryEditForm.touched.labelSizeDescription}
                                                invalidFeedback={productionEntryEditForm.errors.labelSizeDescription}
                                                placeholder="Enter Label Size Description" />
                                        </FormGroup>
                                    }
                                    <Button isOutline color='primary' icon='Add' style={{ height: '46px' }} onClick={() => setIsVisibleOnLabelSize(!isVisibleOnLabelSize)}></Button>
                                </InputGroup>
                            </div>
                            <div className='col-3'>
                                <FormGroup id='productionEntryDate' label='Production Entry Date' isFloating>
                                    <Input onChange={productionEntryEditForm.handleChange}
                                        value={productionEntryEditForm.values.productionEntryDate}
                                        isValid={productionEntryEditForm.isValid}
                                        onBlur={productionEntryEditForm.handleBlur}
                                        isTouched={productionEntryEditForm.touched.productionEntryDate}
                                        invalidFeedback={productionEntryEditForm.errors.productionEntryDate}
                                        type='date'
                                        disabled
                                        placeholder="Select Production Entry Date" />
                                </FormGroup>
                            </div>
                            <div className='col-3'>
                                <FormGroup id='noOfRoll' label='No Of Roll' isFloating>
                                    <Input onChange={productionEntryEditForm.handleChange} value={productionEntryEditForm.values.noOfRoll}
                                        isValid={productionEntryEditForm.isValid}
                                        onBlur={productionEntryEditForm.handleBlur}
                                        isTouched={productionEntryEditForm.touched.noOfRoll}
                                        invalidFeedback={productionEntryEditForm.errors.noOfRoll}
                                        type='number'
                                        placeholder="Enter No Of Roll" />
                                </FormGroup>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color='info'
                            isOutline
                            className='border-0'
                            onClick={closeAndReset}>
                            Close
                        </Button>
                        <Button color='info' type="submit" icon="Save" onClick={productionEntryEditForm.handleSubmit}>
                            Update
                        </Button>
                    </ModalFooter>
                </Modal>

                <Modal
                    setIsOpen={closeAndReset}
                    isOpen={isDeleteOpen}
                    titleId='edit'
                    isStaticBackdrop
                    size='sm'>
                    <ModalHeader>{''}</ModalHeader>
                    <ModalBody>
                        <div className="row g-3">
                            <div className="col-12 d-flex justify-content-center">
                                <h5><strong>Are you sure want to delete ?</strong></h5>
                            </div>
                            <div className='col-10'>
                                <div className='d-flex gap-2 justify-content-end'>
                                    <Button color='danger' size={'sm'} onClick={deleteProductionEntrySubmit}>
                                        Yes
                                    </Button>

                                    <Button color='info' size={'sm'} onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                                        No
                                    </Button>
                                </div>
                            </div>
                            <div className='col-10'></div>
                        </div>
                    </ModalBody>
                </Modal>

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </Page>
        </PageWrapper >
    )
}

export default ProductionEntry