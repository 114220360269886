import React, { useContext, useEffect, useState } from 'react'
import PageWrapper from '../../layout/PageWrapper/PageWrapper'
import Page from '../../layout/Page/Page'
import Collapse from '../bootstrap/Collapse'
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card'
import FormGroup from '../bootstrap/forms/FormGroup'
import Input from '../bootstrap/forms/Input'
import Button, { ButtonGroup } from '../bootstrap/Button'
import { useFormik } from 'formik'
import Select from '../bootstrap/forms/Select'
import Icon from '../icon/Icon'
import { getLicenseKey } from '../../services/application.settings'
import AuthContext from '../../contexts/authContext'
import { showLoader } from '../../services/loader.services'
import AlertService from '../../services/alert.service'
import NoDataMsg from '../../common/components/NoDataMsg'
import InputGroup from '../bootstrap/forms/InputGroup'
import useSortableData from '../../hooks/useSortableData'
import PaginationButtons, { dataPagination } from '../PaginationButtons'
import { addJumboRollStock, getJumboRollStock } from '../../services/stockService'
import { toasts } from '../../services/toaste.service'
import { addOrderReceivedDetails, assignOrder, getAssignOrderList, getOrderReceivedDetails } from '../../services/orderService'
import { getCurrentDateAndTime, getMachineDetails } from '../../services/common.service'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../bootstrap/OffCanvas'
import Checks, { ChecksGroup } from '../bootstrap/forms/Checks'
import Label from '../bootstrap/forms/Label'
import SearchableSelect from '../../common/components/SearchableSelect'
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../bootstrap/Modal'

const AssignOrderToMachine = () => {

    useEffect(() => {
        getAssignOrderDetails()
        getOrderReceivedDetailsList()
        getMachineDetailsList()
        getLotDetailsList()
    }, [])

    const assignOrderForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            orderReceivedDetailsId: '',
            machineDetailsId: '',
            operaterName: '',
            orderQty: 0,
            labelSize: '',
            noOfLabelPerRoll: '',
            noOfRolls: '',
            noOfLabelInOneJumbo: '',
            howManyJumboNeeded: '',
            sizeOfJumbo: '',
            lotNo: '',
            jumboRollStockId: '',
            core: '1”',
            flexRadioDefault: ''
        },
        validate: (values: any) => {
            const errors: {
                orderReceivedDetailsId?: string;
                operaterName?: string;
                machineDetailsId?: string;
                orderQty?: string;
                labelSize?: string;
                noOfLabelPerRoll?: string;
                noOfRolls?: string;
                noOfLabelInOneJumbo?: string;
                howManyJumboNeeded?: string;
                sizeOfJumbo?: string;
            } = {};
            if (!orderReceivedDetailsId?.value) {
                errors.orderReceivedDetailsId = 'Required';
            }
            if (!values.operaterName) {
                errors.operaterName = 'Required';
            }
            if (!machineDetailsId?.value) {
                errors.machineDetailsId = 'Required';
            }
            if (!values.orderQty) {
                errors.orderQty = 'Required';
            }
            if (!values.labelSize) {
                errors.labelSize = 'Required';
            }
            if (!noOfLabelPerRoll) {
                errors.noOfLabelPerRoll = 'Required';
            }
            if (!noOfRolls) {
                errors.noOfRolls = 'Required';
            }
            if (!values.noOfLabelInOneJumbo) {
                errors.noOfLabelInOneJumbo = 'Required';
            }
            if (!values.howManyJumboNeeded) {
                errors.howManyJumboNeeded = 'Required';
            }
            if (!values.sizeOfJumbo) {
                errors.sizeOfJumbo = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { assignOrderSubmit() },
    });

    const columnVisibileForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [assignOrderData, setAssignOrderData] = useState<any>([])
    const [noDataMsg, setNoDataMsg] = useState('')

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(assignOrderData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [lotDetailsData, setLotDetailsData] = useState<any>([])

    // Filter
    const filteredData = onCurrentPageData.filter(
        (i) =>
            i.sno !== null && i.sno.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.customerName !== null && i.customerName.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.poNumber !== null && i.poNumber.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.orderDate !== null && i.orderDate.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.orderItemDescription !== null && i.orderItemDescription.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.orderQty !== null && i.orderQty.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()),
    );

    function setAssignOrderDetails() {
        return ({
            licenseKey: getLicenseKey,
            orderReceivedDetailsId: orderReceivedDetailsId?.value,
            machineDetailsId: machineDetailsId?.value,
            operaterName: assignOrderForm.values.operaterName,
            orderQty: Number(assignOrderForm.values.orderQty),
            labelSize: assignOrderForm.values.labelSize,
            noOfLabelPerRoll: Number(noOfLabelPerRoll),
            noOfRolls: Number(noOfRolls),
            core: assignOrderForm.values.core,
            noOfLabelInOneJumbo: Number(assignOrderForm.values.noOfLabelInOneJumbo),
            howManyJumboNeeded: Number(assignOrderForm.values.howManyJumboNeeded),
            sizeOfJumbo: assignOrderForm.values.sizeOfJumbo,
            userAccountId: Number(userAccountId),
        })
    }

    function assignOrderSubmit() {
        showLoader(true)
        let assignOrderPostData = setAssignOrderDetails()
        if (assignOrderForm.isValid) {
            assignOrder(assignOrderPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset()
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);

                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (assignOrderForm.isValid == false) {
            toasts("Please fill all details", "Error");
        }
    }

    function getAssignOrderDetails() {
        getAssignOrderList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.assignOrderList;
                    if (data != undefined) {
                        setAssignOrderData(data)
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const [orderReceivedDetailsData, setOrderReceivedDetailsData] = useState([])
    const [machineDetailsData, setMachineDetailsData] = useState([])

    function getOrderReceivedDetailsList() {
        getOrderReceivedDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.orderReceivedDetails;
                    if (data != undefined) {
                        setOrderReceivedDetailsData(data)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getMachineDetailsList() {
        getMachineDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.machineDetails;
                    if (data != undefined) {
                        setMachineDetailsData(data)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const [receivedSizeData, setReceivedSizeData] = useState<any>([])
    const [lotNo, setLotNo] = useState<any>([])

    function getLotDetailsList() {
        getJumboRollStock(0, 1, 0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.jumboRollStock;
                    if (data != undefined) {
                        setLotDetailsData(data)
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getJumboRollStockList(lotId: any) {
        getJumboRollStock(lotId, 1, 0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.jumboRollStock;
                    if (data != undefined) {
                        setReceivedSizeData(data)
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const [orderReceivedDetailsId, setOrderReceivedDetailsId] = useState<any>([])
    const [jumboRollStockId, setJumboRollStockId] = useState<any>([])
    const [machineDetailsId, setMachineDetailsId] = useState<any>([])
    const [isOpenModdal, setIsOpenModdal] = useState<any>(false)



    function selectCustomerName(e: any) {
        setOrderReceivedDetailsId(e)
    }

    function selectMachine(e: any) {
        setMachineDetailsId(e)
    }

    const selectLotNo = (e: any) => {
        let selectedData: any = lotDetailsData.find((data: any) => data.lotNo == e?.value);
        setLotNo(e)
        getJumboRollStockList(selectedData.lotId)
    };

    function selectReceivedSize(e: any) {
        setJumboRollStockId(e)
    }

    function closeAndReset() {
        assignOrderForm.resetForm()
        getAssignOrderDetails()
        setAssignMachineOffcanvas(false)
        setIsOpenModdal(false)
    }

    const [assignMachineOffcanvas, setAssignMachineOffcanvas] = useState(false);
    const [noOfLabelPerRoll, setNoOfLabelPerRoll] = useState('');
    const [noOfRolls, setNoOfRolls] = useState<any>('');

    function selectNoofLabelPerRoll(e: any) {
        setNoOfLabelPerRoll(e.target.value)
        let noOfRolls = Number(assignOrderForm.values.orderQty / e.target.value)
        setNoOfRolls(noOfRolls)
    }

    return (
        <PageWrapper title={`Assigned Order List`}>
            <Page>
                <Card stretch data-tour='list'>
                    <CardHeader borderSize={1}>
                        <CardLabel icon='List' iconColor='info' className="col-lg-7">
                            <CardTitle tag='div' className='h5'>Assigned Order List</CardTitle>
                        </CardLabel>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                        <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                            <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                            <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibileForm.handleChange} value={columnVisibileForm.values.searchInput} />
                        </ButtonGroup>

                        <CardActions className="d-print-none">
                            <Button color='primary' icon='Add' isLight onClick={() => setIsOpenModdal(true)}>
                                Assign
                            </Button>
                        </CardActions>
                    </CardHeader>
                    <CardBody>
                        <table className='table table-modern table-hover'>
                            <thead>
                                <tr>
                                    <th scope='col' onClick={() => requestSort('sno')} className='cursor-pointer text-decoration-underline'>S.No <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' /></th>
                                    <th scope='col' onClick={() => requestSort('customerName')} className='cursor-pointer text-decoration-underline'>Customer Name <Icon size='lg' className={getClassNamesFor('customerName')} icon='FilterList' /></th>
                                    <th scope='col' onClick={() => requestSort('poNumber')} className='cursor-pointer text-decoration-underline'>Machine <Icon size='lg' className={getClassNamesFor('poNumber')} icon='FilterList' /></th>
                                    <th scope='col' onClick={() => requestSort('customerName')} className='cursor-pointer text-decoration-underline'>Operater Name <Icon size='lg' className={getClassNamesFor('customerName')} icon='FilterList' /></th>
                                    <th scope='col' onClick={() => requestSort('orderQty')} className='cursor-pointer text-decoration-underline'>Order Qty <Icon size='lg' className={getClassNamesFor('orderQty')} icon='FilterList' /></th>
                                    <th scope='col' onClick={() => requestSort('labelSize')} className='cursor-pointer text-decoration-underline'>Label Size <Icon size='lg' className={getClassNamesFor('orderDate')} icon='FilterList' /></th>
                                    <th scope='col' onClick={() => requestSort('noOfLabelPerRoll')} className='cursor-pointer text-decoration-underline'>No of Label (Per Roll) <Icon size='lg' className={getClassNamesFor('orderDate')} icon='FilterList' /></th>
                                    <th scope='col' onClick={() => requestSort('noOfRolls')} className='cursor-pointer text-decoration-underline'>No Of Rolls <Icon size='lg' className={getClassNamesFor('orderDate')} icon='FilterList' /></th>
                                    <th scope='col' onClick={() => requestSort('core')} className='cursor-pointer text-decoration-underline'>Core <Icon size='lg' className={getClassNamesFor('orderDate')} icon='FilterList' /></th>
                                    <th scope='col' onClick={() => requestSort('noOfLabelInOneJumbo')} className='cursor-pointer text-decoration-underline'>No of Label in One Jumbo <Icon size='lg' className={getClassNamesFor('orderDate')} icon='FilterList' /></th>
                                    <th scope='col' onClick={() => requestSort('howManyJumboNeeded')} className='cursor-pointer text-decoration-underline'>How Many Jumbo Need <Icon size='lg' className={getClassNamesFor('orderDate')} icon='FilterList' /></th>
                                    <th scope='col' onClick={() => requestSort('sizeOfJumbo')} className='cursor-pointer text-decoration-underline'>Size Of Jumbo <Icon size='lg' className={getClassNamesFor('orderDate')} icon='FilterList' /></th>
                                </tr>
                            </thead>
                            {assignOrderData != "" ? <>{filteredData.map((item: any) => (
                                <tbody key={item.orderAssignId}>
                                    <tr>
                                        <td>{item.sno}</td>
                                        <td>{item.customerName}</td>
                                        <td>{item.machineName}</td>
                                        <td>{item.operaterName}</td>
                                        <td>{item.orderQty}</td>
                                        <td>{item.labelSize}</td>
                                        <td>{item.noOfLabelPerRoll}</td>
                                        <td>{item.noOfRolls}</td>
                                        <td>{item.core}</td>
                                        <td>{item.noOfLabelInOneJumbo}</td>
                                        <td>{item.howManyJumboNeeded}</td>
                                        <td>{item.sizeOfJumbo}</td>
                                    </tr>
                                </tbody>
                            ))} </> : <NoDataMsg columnsCount={10} msg={noDataMsg} />
                            }
                        </table>
                    </CardBody>
                    <PaginationButtons className="d-print-none" data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                </Card>

                <OffCanvas setOpen={setAssignMachineOffcanvas} isOpen={assignMachineOffcanvas} titleId='addProductOffcanvas' isBodyScroll placement='end' tag="form" noValidate onSubmit={assignOrderForm.handleSubmit} isNotClose>
                    <OffCanvasHeader setOpen={closeAndReset}>
                        <OffCanvasTitle id='addProductOffcanvas'>Assign Order to Machine</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <div className='row g-4'>
                            <div className='col-12'>
                                <FormGroup id='orderReceivedDetailsId' label='Customer Name'>
                                    <SearchableSelect
                                        ariaLabel={''}
                                        placeholder='Customer Name'
                                        className="form-control"
                                        value={orderReceivedDetailsId}
                                        onBlur={assignOrderForm.handleBlur}
                                        isTouched={assignOrderForm.touched.orderReceivedDetailsId}
                                        invalidFeedback={assignOrderForm.errors.orderReceivedDetailsId}
                                        list={orderReceivedDetailsData.map((data: any) => (
                                            { value: data.orderReceivedDetailsId, label: data.customerName }
                                        ))}
                                        onChange={(e: any) => selectCustomerName(e)} />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='machineDetailsId' label='Machine Name'>
                                    <SearchableSelect
                                        ariaLabel={''}
                                        placeholder='Machine Name'
                                        className="form-control"
                                        value={machineDetailsId}
                                        onBlur={assignOrderForm.handleBlur}
                                        isTouched={assignOrderForm.touched.machineDetailsId}
                                        invalidFeedback={assignOrderForm.errors.machineDetailsId}
                                        list={machineDetailsData.map((data: any) => (
                                            { value: data.machineDetailsId, label: data.machineName }
                                        ))}
                                        onChange={(e: any) => selectMachine(e)} />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='operaterName' label='Operater Name'>
                                    <Input onChange={assignOrderForm.handleChange}
                                        value={assignOrderForm.values.operaterName}
                                        isValid={assignOrderForm.isValid}
                                        onBlur={assignOrderForm.handleBlur}
                                        isTouched={assignOrderForm.touched.operaterName}
                                        invalidFeedback={assignOrderForm.errors.operaterName}
                                        placeholder="Enter Operater Name" />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='orderQty' label='Order Qty'>
                                    <Input onChange={assignOrderForm.handleChange}
                                        value={assignOrderForm.values.orderQty}
                                        isValid={assignOrderForm.isValid}
                                        onBlur={assignOrderForm.handleBlur}
                                        isTouched={assignOrderForm.touched.orderQty}
                                        invalidFeedback={assignOrderForm.errors.orderQty}
                                        placeholder="Enter Order Qty" />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='labelSize' label='Label Size'>
                                    <Input onChange={assignOrderForm.handleChange}
                                        value={assignOrderForm.values.labelSize}
                                        isValid={assignOrderForm.isValid}
                                        onBlur={assignOrderForm.handleBlur}
                                        isTouched={assignOrderForm.touched.labelSize}
                                        invalidFeedback={assignOrderForm.errors.labelSize}
                                        placeholder="Enter Label Size" />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='noOfLabelPerRoll' label='No of Label ( Per Roll )'>
                                    <Input onChange={assignOrderForm.handleChange}
                                        value={assignOrderForm.values.noOfLabelPerRoll}
                                        isValid={assignOrderForm.isValid}
                                        onBlur={assignOrderForm.handleBlur}
                                        isTouched={assignOrderForm.touched.noOfLabelPerRoll}
                                        invalidFeedback={assignOrderForm.errors.noOfLabelPerRoll}
                                        placeholder="Enter No of Label ( Per Roll )" />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='noOfRolls' label='No Of Rolls'>
                                    <Input
                                        value={noOfRolls}
                                        placeholder="Enter No Of Rolls" />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <InputGroup>
                                    <Label>Core :&nbsp;&nbsp;&nbsp;</Label>
                                    <ChecksGroup isInline>
                                        <Checks
                                            type='radio'
                                            id='inlineRadioOne'
                                            label='1”'
                                            name='radios'
                                            value='1”'
                                            onChange={assignOrderForm.handleChange}
                                            checked={assignOrderForm.values.core}
                                        />
                                        <Checks
                                            type='radio'
                                            id='inlineRadioTwo'
                                            label='3”'
                                            name='radios'
                                            value='3”'
                                            onChange={assignOrderForm.handleChange}
                                            checked={assignOrderForm.values.core}
                                        />
                                    </ChecksGroup>
                                </InputGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='noOfLabelInOneJumbo' label='No of Label in One Jumbo'>
                                    <Input onChange={assignOrderForm.handleChange}
                                        value={assignOrderForm.values.noOfLabelInOneJumbo}
                                        isValid={assignOrderForm.isValid}
                                        onBlur={assignOrderForm.handleBlur}
                                        isTouched={assignOrderForm.touched.noOfLabelInOneJumbo}
                                        invalidFeedback={assignOrderForm.errors.noOfLabelInOneJumbo}
                                        placeholder="Enter No of Label in One Jumbo" />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='howManyJumboNeeded' label='How Many Jumbo Need'>
                                    <Input onChange={assignOrderForm.handleChange}
                                        value={assignOrderForm.values.howManyJumboNeeded}
                                        isValid={assignOrderForm.isValid}
                                        onBlur={assignOrderForm.handleBlur}
                                        isTouched={assignOrderForm.touched.howManyJumboNeeded}
                                        invalidFeedback={assignOrderForm.errors.howManyJumboNeeded}
                                        placeholder="Enter How Many Jumbo Need" />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='sizeOfJumbo' label='Size Of Jumbo'>
                                    <Input onChange={assignOrderForm.handleChange}
                                        value={assignOrderForm.values.sizeOfJumbo}
                                        isValid={assignOrderForm.isValid}
                                        onBlur={assignOrderForm.handleBlur}
                                        isTouched={assignOrderForm.touched.sizeOfJumbo}
                                        invalidFeedback={assignOrderForm.errors.sizeOfJumbo}
                                        placeholder="Enter Size Of Jumbo" />
                                </FormGroup>
                            </div>
                        </div>
                    </OffCanvasBody>
                    <div className='row m-0'>
                        <div className='col-12 p-3'>
                            <Button color='info' className='w-100' type="submit" icon="Save" isDisable={!assignOrderForm.isValid && !!assignOrderForm.submitCount}>
                                Save
                            </Button>
                        </div>
                    </div>
                </OffCanvas>

                <Modal
                    setIsOpen={setIsOpenModdal}
                    isOpen={isOpenModdal}
                    titleId='upcomingEdit'
                    isCentered
                    isStaticBackdrop
                    size='xl'>
                    <ModalHeader setIsOpen={setIsOpenModdal}>
                        <ModalTitle id='examplemailcontent'>
                            <div className="h4">Order Assign To Machine
                            </div>
                        </ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <form>
                            <div className='row g-4'>
                                <div className='col-3'>
                                    <FormGroup id='orderReceivedDetailsId' label='Customer Name' isFloating>
                                        <SearchableSelect
                                            ariaLabel={''}
                                            isFloating
                                            placeholder='Customer Name'
                                            className="form-control"
                                            value={orderReceivedDetailsId}
                                            onBlur={assignOrderForm.handleBlur}
                                            isTouched={assignOrderForm.touched.orderReceivedDetailsId}
                                            invalidFeedback={assignOrderForm.errors.orderReceivedDetailsId}
                                            list={orderReceivedDetailsData.map((data: any) => (
                                                { value: data.orderReceivedDetailsId, label: data.customerName }
                                            ))}
                                            onChange={(e: any) => selectCustomerName(e)} />
                                    </FormGroup>
                                </div>
                                <div className='col-3'>
                                    <FormGroup id='machineDetailsId' label='Machine Name' isFloating>
                                        <SearchableSelect
                                            isFloating
                                            ariaLabel={''}
                                            placeholder='Machine Name'
                                            className="form-control"
                                            value={machineDetailsId}
                                            onBlur={assignOrderForm.handleBlur}
                                            isTouched={assignOrderForm.touched.machineDetailsId}
                                            invalidFeedback={assignOrderForm.errors.machineDetailsId}
                                            list={machineDetailsData.map((data: any) => (
                                                { value: data.machineDetailsId, label: data.machineName }
                                            ))}
                                            onChange={(e: any) => selectMachine(e)} />
                                    </FormGroup>
                                </div>
                                <div className='col-3'>
                                    <FormGroup id='operaterName' label='Operater Name' isFloating>
                                        <Input onChange={assignOrderForm.handleChange}
                                            value={assignOrderForm.values.operaterName}
                                            isValid={assignOrderForm.isValid}
                                            onBlur={assignOrderForm.handleBlur}
                                            isTouched={assignOrderForm.touched.operaterName}
                                            invalidFeedback={assignOrderForm.errors.operaterName}
                                            placeholder="Enter Operater Name" />
                                    </FormGroup>
                                </div>
                                <div className='col-3'>
                                    <FormGroup id='orderQty' label='Order Qty' isFloating>
                                        <Input onChange={assignOrderForm.handleChange}
                                            value={assignOrderForm.values.orderQty}
                                            isValid={assignOrderForm.isValid}
                                            onBlur={assignOrderForm.handleBlur}
                                            isTouched={assignOrderForm.touched.orderQty}
                                            invalidFeedback={assignOrderForm.errors.orderQty}
                                            placeholder="Enter Order Qty" />
                                    </FormGroup>
                                </div>
                                <div className='col-3'>
                                    <FormGroup id='labelSize' label='Label Size' isFloating>
                                        <Input onChange={assignOrderForm.handleChange}
                                            value={assignOrderForm.values.labelSize}
                                            isValid={assignOrderForm.isValid}
                                            onBlur={assignOrderForm.handleBlur}
                                            isTouched={assignOrderForm.touched.labelSize}
                                            invalidFeedback={assignOrderForm.errors.labelSize}
                                            placeholder="Enter Label Size" />
                                    </FormGroup>
                                </div>
                                <div className='col-3'>
                                    <FormGroup id='noOfLabelPerRoll' label='No of Label ( Per Roll )' isFloating>
                                        <Input onChange={selectNoofLabelPerRoll}
                                            value={noOfLabelPerRoll}
                                            isValid={assignOrderForm.isValid}
                                            onBlur={assignOrderForm.handleBlur}
                                            isTouched={assignOrderForm.touched.noOfLabelPerRoll}
                                            invalidFeedback={assignOrderForm.errors.noOfLabelPerRoll}
                                            placeholder="Enter Order Qty" />
                                    </FormGroup>
                                </div>
                                <div className='col-3'>
                                    <FormGroup id='noOfRolls' label='No Of Rolls' isFloating>
                                        <Input
                                            value={noOfRolls}
                                            disabled
                                            placeholder="Enter No Of Rolls" />
                                    </FormGroup>
                                </div>
                                <div className='col-3'>
                                    <InputGroup>
                                        <Label>Core :&nbsp;&nbsp;&nbsp;</Label>
                                        <ChecksGroup isInline>
                                            <Checks
                                                type='radio'
                                                name='core'
                                                id='core1'
                                                label='1”'
                                                value='1”'
                                                onChange={assignOrderForm.handleChange}
                                                checked={assignOrderForm.values.core}
                                            />
                                            <Checks
                                                type='radio'
                                                name='core'
                                                id='core2'
                                                label='3”'
                                                value='3”'
                                                onChange={assignOrderForm.handleChange}
                                                checked={assignOrderForm.values.core}
                                            />
                                        </ChecksGroup>
                                    </InputGroup>
                                </div>
                                <div className='col-3'>
                                    <FormGroup id='noOfLabelInOneJumbo' label='No of Label in One Jumbo' isFloating>
                                        <Input onChange={assignOrderForm.handleChange}
                                            value={assignOrderForm.values.noOfLabelInOneJumbo}
                                            isValid={assignOrderForm.isValid}
                                            onBlur={assignOrderForm.handleBlur}
                                            isTouched={assignOrderForm.touched.noOfLabelInOneJumbo}
                                            invalidFeedback={assignOrderForm.errors.noOfLabelInOneJumbo}
                                            placeholder="Enter No of Label in One Jumbo" />
                                    </FormGroup>
                                </div>
                                <div className='col-3'>
                                    <FormGroup id='howManyJumboNeeded' label='How Many Jumbo Need' isFloating>
                                        <Input onChange={assignOrderForm.handleChange}
                                            value={assignOrderForm.values.howManyJumboNeeded}
                                            isValid={assignOrderForm.isValid}
                                            onBlur={assignOrderForm.handleBlur}
                                            isTouched={assignOrderForm.touched.howManyJumboNeeded}
                                            invalidFeedback={assignOrderForm.errors.howManyJumboNeeded}
                                            placeholder="Enter How Many Jumbo Need" />
                                    </FormGroup>
                                </div>
                                <div className='col-3'>
                                    <FormGroup id='sizeOfJumbo' label='Size Of Jumbo' isFloating>
                                        <Input onChange={assignOrderForm.handleChange}
                                            value={assignOrderForm.values.sizeOfJumbo}
                                            isValid={assignOrderForm.isValid}
                                            onBlur={assignOrderForm.handleBlur}
                                            isTouched={assignOrderForm.touched.sizeOfJumbo}
                                            invalidFeedback={assignOrderForm.errors.sizeOfJumbo}
                                            placeholder="Enter Size Of Jumbo" />
                                    </FormGroup>
                                </div>
                            </div>
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color='info'
                            isOutline
                            className='border-0'
                            onClick={() => setIsOpenModdal(false)}>
                            Close
                        </Button>
                        <Button color='info' type="submit" icon="Save" isDisable={!assignOrderForm.isValid && !!assignOrderForm.submitCount}
                            onClick={assignOrderForm.handleSubmit}>
                            Save
                        </Button>
                    </ModalFooter>
                </Modal>
                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </Page>
        </PageWrapper >
    )
}

export default AssignOrderToMachine