import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getOrderReceivedDetails = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

api.get(getAPIURL() + `getOrderReceivedDetails/${getLicenseKey}`,
    response => {
        success(response)
    },
    error => {
        failure("getOrderReceivedDetails - " + error.message)
        console.log('error caught in service : getOrderReceivedDetails')
    },
);

export const addOrderReceivedDetails = (orderReceivedDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addOrderReceivedDetails`, orderReceivedDetailsPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addOrderReceivedDetails')
        }
    );

export const getAssignOrderList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getAssignOrderList/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getAssignOrderList - " + error.message)
            console.log('error caught in service : getAssignOrderList')
        },
    );

export const assignOrder = (assignOrderPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `assignOrder`, assignOrderPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : assignOrder')
        }
    );

export const addProductionEntry = (productionEntryPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addProductionEntry`, productionEntryPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addProductionEntry')
        }
    );

export const getProductionEntryList = (productionEntryId: any, orderReceivedDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getProductionEntryList/${getLicenseKey}/${productionEntryId}/${orderReceivedDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getProductionEntryList - " + error.message)
            console.log('error caught in service : getProductionEntryList')
        },
    );

export const updateProductionEntry = (productionEntryPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateProductionEntry`, productionEntryPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateProductionEntry')
        }
    );

export const deleteProductionEntry = (productionEntryPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteProductionEntry`, productionEntryPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteProductionEntry')
        }
    );

export const updateOrderReceivedQuantityDetails = (orderReceivedDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateOrderReceivedQuantityDetails`, orderReceivedDetailsPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateOrderReceivedQuantityDetails')
        }
    );

export const getProductionEntryCount = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getProductionEntryCount/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getProductionEntryCount - " + error.message)
            console.log('error caught in service : getProductionEntryCount')
        },
    );

export const cancelOrderReceivedDetails = (orderReceivedDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `cancelOrderReceivedDetails`, orderReceivedDetailsPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : cancelOrderReceivedDetails')
        }
    );

export const deleteProductionEntryCount = (productionEntryCountPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteProductionEntryCount`, productionEntryCountPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteProductionEntryCount')
        }
    )