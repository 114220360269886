import { useContext, useEffect, useState } from "react";
import PageWrapper from "../layout/PageWrapper/PageWrapper";
import Page from "../layout/Page/Page";
import AlertService from "../services/alert.service";
import useDarkMode from "../hooks/useDarkMode";
import { useFormik } from "formik";
import useSortableData from "../hooks/useSortableData";
import classNames from "classnames";
import { toasts } from "../services/toaste.service";
import { TableLoader, showLoader } from "../services/loader.services";
import NoDataMsg from "../common/components/NoDataMsg";
import { getAPIURL, getLicenseKey } from "../services/application.settings";
import AuthContext from "../contexts/authContext";
import PaginationButtons, { dataPagination } from "./PaginationButtons";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "./bootstrap/Card";
import Button, { ButtonGroup } from "./bootstrap/Button";
import Icon from "./icon/Icon";
import Input from "./bootstrap/forms/Input";
import { getPackingList } from "../services/stockService";

function ChallanDetails() {

    useEffect(() => {
        getPackingDetailsList()
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const { darkModeStatus } = useDarkMode();
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [challanDetails, setChallanDetails] = useState<any>([])
    const [noDataMsg, setNoDataMsg] = useState('')
    const [dataStatus, setDataStatus] = useState(false)

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(challanDetails);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);


    const columnVisibileForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = onCurrentPageData.filter(
        (i) =>
            // Name           
            i.sno !== null && i.sno.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.packageNo !== null && i.packageNo.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.packageDate !== null && i.packageDate.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.customerName !== null && i.customerName.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.poNumber !== null && i.poNumber.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.orderDate !== null && i.orderDate.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ,
    );

    function getPackingDetailsList() {
        setDataStatus(false)
        getPackingList(0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.packingList;
                    if (data != undefined) {
                        setChallanDetails(data)
                        setDataStatus(true)
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                    setDataStatus(true)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                    setDataStatus(true)
                }
            }, error => {
                toasts(error, "Error")
                setDataStatus(true)
            }
        )
    }

    function onPrint(i: any) {
        if (i != undefined) {
            let selectedData = i
            window.open(selectedData.pdfPath);
        }
    }

    return (
        <>
            <PageWrapper title={`Delivery Challan`}>
                <Page container='fluid'>
                    <Card stretch data-tour='list'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-7">
                                <CardTitle tag='div' className='h5'>Packing Slip</CardTitle>
                            </CardLabel>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibileForm.handleChange} value={columnVisibileForm.values.searchInput} />
                            </ButtonGroup>
                        </CardHeader>
                        <CardBody className='table-responsive' isScrollable>
                            {!dataStatus ?
                                <TableLoader /> :
                                <table className='table table-modern table-hover'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            <th scope='col' onClick={() => requestSort('packageNo')}
                                                className='cursor-pointer text-decoration-underline'>Package No{' '}
                                                <Icon size='lg' className={getClassNamesFor('packageNo')} icon='FilterList' />
                                            </th>
                                            <th scope='col' onClick={() => requestSort('packageDate')}
                                                className='cursor-pointer text-decoration-underline'>Package Date{' '}
                                                <Icon size='lg' className={getClassNamesFor('packageDate')} icon='FilterList' />
                                            </th>
                                            <th scope='col' onClick={() => requestSort('customerName')}
                                                className='cursor-pointer text-decoration-underline'>Customer Name{' '}
                                                <Icon size='lg' className={getClassNamesFor('customerName')} icon='FilterList' />
                                            </th>
                                            <th scope='col' onClick={() => requestSort('poNumber')}
                                                className='cursor-pointer text-decoration-underline'>Po Number{' '}
                                                <Icon size='lg' className={getClassNamesFor('poNumber')} icon='FilterList' />
                                            </th>
                                            <th scope='col' onClick={() => requestSort('orderDate')}
                                                className='cursor-pointer text-decoration-underline'>Order Date{' '}
                                                <Icon size='lg' className={getClassNamesFor('orderDate')} icon='FilterList' />
                                            </th> 
                                            <th>Action</th>                                           
                                        </tr>
                                    </thead>
                                    {challanDetails != "" ? <>
                                        {filteredData.map((item: any) => (
                                            <tbody key={item.boxLabelId}>
                                                <tr>
                                                    <td>{item.sno}</td>
                                                    <td>{item.packageNo}</td>
                                                    <td>{item.packageDate}</td>
                                                    <td>{item.customerName}</td>
                                                    <td>{item.poNumber}</td>
                                                    <td>{item.orderDate}</td>                                                    
                                                    <td className="d-print-none"><Button color='success' isLight icon='Download' aria-label='Download' onClick={() => onPrint(item)}></Button></td>
                                                </tr>
                                            </tbody>
                                        ))} </> :
                                        <NoDataMsg columnsCount={10} msg={noDataMsg} />}
                                </table>
                            }
                        </CardBody>
                        <PaginationButtons className="d-print-none" data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                    </Card>

                    <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
                </Page>
            </PageWrapper >
        </>
    )
}
export default ChallanDetails;