import React, { useContext, useEffect, useState } from 'react'
import PageWrapper from '../../layout/PageWrapper/PageWrapper'
import Page from '../../layout/Page/Page'
import Collapse from '../bootstrap/Collapse'
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card'
import FormGroup from '../bootstrap/forms/FormGroup'
import Input from '../bootstrap/forms/Input'
import Button, { ButtonGroup } from '../bootstrap/Button'
import { useFormik } from 'formik'
import Select from '../bootstrap/forms/Select'
import Icon from '../icon/Icon'
import { getLicenseKey } from '../../services/application.settings'
import AuthContext from '../../contexts/authContext'
import { showLoader } from '../../services/loader.services'
import AlertService from '../../services/alert.service'
import NoDataMsg from '../../common/components/NoDataMsg'
import InputGroup from '../bootstrap/forms/InputGroup'
import useSortableData from '../../hooks/useSortableData'
import PaginationButtons, { dataPagination } from '../PaginationButtons'
import { addJumboRollStock, getJumboRollStock, getJumboRollStockCount } from '../../services/stockService'
import { toasts } from '../../services/toaste.service'
import { getSubModuleType } from '../../services/common.service'
import SearchableSelect from '../../common/components/SearchableSelect'

const JumboRollStock = () => {

    useEffect(() => {
        getLotDetailsList()
        getJumboRollStockList()
    }, [])

    const jumboRollStockForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            invoiceNo: '',
            supplierName: '',
            receivedSize: '',
            totalQr: '',
            lotId: '',
            lotNo: ''
        },
        validate: (values: any) => {
            const errors: {
                invoiceNo?: string;
                supplierName?: string;
                receivedSize?: string;
                totalQr?: string;
                lotId?: string;
                lotNo?: string;
            } = {};
            if (!values.invoiceNo) {
                errors.invoiceNo = 'Required';
            }
            if (!values.supplierName) {
                errors.supplierName = 'Required';
            }
            if (!receivedSize?.value && isVisibleOnReceivedSize == true) {
                errors.receivedSize = 'Required';
            }
            if (!receivedSize && isVisibleOnReceivedSize == false) {
                errors.receivedSize = 'Required';
            }
            if (!values.totalQr) {
                errors.totalQr = 'Required';
            }
            if (!lotNo && isVisibleOnMillLotNo == true) {
                errors.lotId = 'Required';
            }
            if (!lotNo && isVisibleOnMillLotNo == false) {
                errors.lotNo = 'Required';
            }

            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { addJumboRollStockSubmit() },
    });

    const columnVisibileForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [jumboRollStockData, setJumboRollStockData] = useState<any>([])
    const [noDataMsg, setNoDataMsg] = useState('')

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(jumboRollStockData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [lotDetailsData, setLotDetailsData] = useState<any>([])
    const [isVisibleOnMillLotNo, setIsVisibleOnMillLotNo] = useState(false)
    const [lot, setLot] = useState<any>([])
    const [lotNo, setLotNo] = useState('')
    const [lotId, setLotId] = useState('')

    // Filter
    const filteredData = onCurrentPageData.filter(
        (i) =>
            i.sno !== null && i.sno.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.invoiceNo !== null && i.invoiceNo.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.supplierName !== null && i.supplierName.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.receivedSize !== null && i.receivedSize.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.totalQr !== null && i.totalQr.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.dateStamp !== null && i.dateStamp.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()),
    );

    const toggleVisibilityOnMillLotNo = () => {
        setIsVisibleOnMillLotNo(!isVisibleOnMillLotNo);
        setLotNo('')
        setLotId('')
    };

    const selectLotNo = (e: any) => {
        setReceivedSize('')
        if (isVisibleOnMillLotNo) {
            let selectedData: any = lotDetailsData.find((data: any) => data.subModuleTypeId == e?.value);
            setLotNo(selectedData?.subModuleType)
            setLotId(selectedData?.subModuleTypeId)
            setLot(e)
            getReceivedSizeList(selectedData?.subModuleTypeId)
        } else {
            setLotNo(e.target.value)
        }
    };

    function getLotDetailsList() {
        getSubModuleType(1, 1,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.subModuleType;
                    if (data != undefined) {
                        setLotDetailsData(data)
                        setIsVisibleOnMillLotNo(true)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setIsVisibleOnMillLotNo(false)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const [receivedSizeData, setReceivedSizeData] = useState([])
    const [isVisibleOnReceivedSize, setIsVisibleOnReceivedSize] = useState(false)
    const [receivedSize, setReceivedSize] = useState<any>('')

    const selectReceivedSize = (e: any) => {
        if (isVisibleOnReceivedSize) {
            setReceivedSize(e)
        } else {
            setReceivedSize(e.target.value)
        }
    };

    function getReceivedSizeList(lotId: any) {
        getJumboRollStockCount(lotId, 0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.jumboRollStockCount;
                    if (data != undefined) {
                        setReceivedSizeData(data)
                        setIsVisibleOnReceivedSize(true)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setIsVisibleOnReceivedSize(false)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setJumboRollStockDetails() {
        return ({
            licenseKey: getLicenseKey,
            lotNo: lotNo,
            invoiceNo: jumboRollStockForm.values.invoiceNo,
            supplierName: jumboRollStockForm.values.supplierName,
            receivedSize: isVisibleOnReceivedSize ? receivedSize?.value : receivedSize,
            totalQr: jumboRollStockForm.values.totalQr,
            userAccountId: userAccountId,
        })
    }

    function addJumboRollStockSubmit() {
        showLoader(true)
        let jumboRollStockPostData = setJumboRollStockDetails()
        if (jumboRollStockForm.isValid) {
            addJumboRollStock(jumboRollStockPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset()
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);

                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (jumboRollStockForm.isValid == false) {
            toasts("Please fill all details", "Error");
        }
    }

    function getJumboRollStockList() {
        getJumboRollStock(0, 0, 0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.jumboRollStock;
                    if (data != undefined) {
                        setJumboRollStockData(data)
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function onPrint(i: any) {
        if (i != undefined) {
            let selectedData = i
            window.open(selectedData.pdfPath);
        }
    }

    function closeAndReset() {
        jumboRollStockForm.resetForm()
        getJumboRollStockList()
        getLotDetailsList()
        setLotId('')
        setLotNo('')
        setLot([])
        setReceivedSize('')
    }

    return (
        <PageWrapper title={`Generate QR`}>
            <Page>
                <Collapse isOpen={isOpenListCard}>
                    <Card stretch data-tour='list' className='d-print-none' tag="form" noValidate onSubmit={jumboRollStockForm.handleSubmit}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='QrCode' iconColor='info'>
                                <CardTitle>
                                    Generate QR For Jumbo Roll
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row g-4'>
                                <div className='col-3'>
                                    <InputGroup>
                                        {isVisibleOnMillLotNo ?
                                            <FormGroup id='lotId' label='Lot No' isFloating>
                                                {/* <Select
                                                    ariaLabel='Lot No'
                                                    onChange={selectLotNo}
                                                    value={lotId}
                                                    isValid={jumboRollStockForm.isValid}
                                                    onBlur={jumboRollStockForm.handleBlur}
                                                    isTouched={jumboRollStockForm.touched.lotId}
                                                    invalidFeedback={jumboRollStockForm.errors.lotId}
                                                    list={lotDetailsData.map((data: any) => (
                                                        { value: data.subModuleTypeId, text: data.subModuleType }
                                                    ))}
                                                /> */}
                                                <SearchableSelect
                                                    isFloating
                                                    ariaLabel={''}
                                                    placeholder='Select City'
                                                    className="form-control"
                                                    value={lot}
                                                    isValid={jumboRollStockForm.isValid}
                                                    onBlur={jumboRollStockForm.handleBlur}
                                                    list={lotDetailsData.map((data: any) => (
                                                        { value: data.subModuleTypeId, label: data.subModuleType }
                                                    ))}
                                                    onChange={e => selectLotNo(e)} />
                                            </FormGroup> :
                                            <FormGroup id='lotNo' label='Lot No' isFloating>
                                                <Input onInput={selectLotNo}
                                                    value={lotNo}
                                                    isValid={jumboRollStockForm.isValid}
                                                    onBlur={jumboRollStockForm.handleBlur}
                                                    isTouched={jumboRollStockForm.touched.lotNo}
                                                    invalidFeedback={jumboRollStockForm.errors.lotNo}
                                                    placeholder="Enter Lot No" />
                                            </FormGroup>
                                        }
                                        <Button isOutline color='primary' icon='Add' style={{ height: '46px' }} onClick={toggleVisibilityOnMillLotNo}></Button>
                                    </InputGroup>
                                </div>
                                <div className='col-3'>
                                    <FormGroup id='invoiceNo' label='Invoice No' isFloating>
                                        <Input onChange={jumboRollStockForm.handleChange} value={jumboRollStockForm.values.invoiceNo}
                                            isValid={jumboRollStockForm.isValid}
                                            onBlur={jumboRollStockForm.handleBlur}
                                            isTouched={jumboRollStockForm.touched.invoiceNo}
                                            invalidFeedback={jumboRollStockForm.errors.invoiceNo}
                                            placeholder="Enter Invoice No" />
                                    </FormGroup>
                                </div>
                                <div className='col-3'>
                                    <FormGroup id='supplierName' label='Supplier Name' isFloating>
                                        <Input onChange={jumboRollStockForm.handleChange} value={jumboRollStockForm.values.supplierName}
                                            isValid={jumboRollStockForm.isValid}
                                            onBlur={jumboRollStockForm.handleBlur}
                                            isTouched={jumboRollStockForm.touched.supplierName}
                                            invalidFeedback={jumboRollStockForm.errors.supplierName}
                                            placeholder="Enter Supplier Name" />
                                    </FormGroup>
                                </div>
                                <div className='col-3'>
                                    <InputGroup>
                                        {isVisibleOnReceivedSize ?
                                            <FormGroup id='receivedSize' label='Received Size' isFloating>
                                                <SearchableSelect
                                                    isFloating
                                                    ariaLabel={''}
                                                    placeholder='Select Received Size'
                                                    className="form-control"
                                                    value={receivedSize}
                                                    isValid={jumboRollStockForm.isValid}
                                                    onBlur={jumboRollStockForm.handleBlur}
                                                    list={receivedSizeData.map((data: any) => (
                                                        { value: data.receivedSize, label: data.receivedSize }
                                                    ))}
                                                    onChange={e => selectReceivedSize(e)} />
                                            </FormGroup> :
                                            <FormGroup id='receivedSize' label='Received Size' isFloating>
                                                <Input onInput={selectReceivedSize}
                                                    value={receivedSize}
                                                    isValid={jumboRollStockForm.isValid}
                                                    onBlur={jumboRollStockForm.handleBlur}
                                                    isTouched={jumboRollStockForm.touched.receivedSize}
                                                    invalidFeedback={jumboRollStockForm.errors.receivedSize}
                                                    placeholder="Enter Received Size" />
                                            </FormGroup>
                                        }
                                        <Button isOutline color='primary' icon='Add' style={{ height: '46px' }} onClick={() => setIsVisibleOnReceivedSize(!isVisibleOnReceivedSize)}></Button>
                                    </InputGroup>
                                </div>
                                {/* <div className='col-3'>
                                    <FormGroup id='receivedSize' label='Received Size' isFloating>
                                        <Input onChange={jumboRollStockForm.handleChange} value={jumboRollStockForm.values.receivedSize}
                                            isValid={jumboRollStockForm.isValid}
                                            onBlur={jumboRollStockForm.handleBlur}
                                            isTouched={jumboRollStockForm.touched.receivedSize}
                                            invalidFeedback={jumboRollStockForm.errors.receivedSize}
                                            placeholder="Enter Received Size" />
                                    </FormGroup>
                                </div> */}
                                <div className='col-3'>
                                    <FormGroup id='totalQr' label='Total QR' isFloating>
                                        <Input onChange={jumboRollStockForm.handleChange} value={jumboRollStockForm.values.totalQr}
                                            isValid={jumboRollStockForm.isValid}
                                            onBlur={jumboRollStockForm.handleBlur}
                                            isTouched={jumboRollStockForm.touched.totalQr}
                                            invalidFeedback={jumboRollStockForm.errors.totalQr}
                                            type='number'
                                            placeholder="Enter Total QR" />
                                    </FormGroup>
                                </div>
                                <div className='col-md-2'>
                                    <Button className="mt-2" icon="WifiProtectedSetup" color='primary' onClick={jumboRollStockForm.handleSubmit}>
                                        Generate
                                    </Button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                {jumboRollStockData != '' ?
                    <Card stretch data-tour='list' className='d-print-none'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle>
                                    Jumbo Roll Stock List
                                </CardTitle>
                            </CardLabel>
                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibileForm.handleChange} value={columnVisibileForm.values.searchInput} />
                            </ButtonGroup>
                        </CardHeader>
                        <CardBody isScrollable>
                            <table className='table table-modern table-hover'>
                                <thead>
                                    <tr>
                                        <th scope='col' onClick={() => requestSort('sno')} className='cursor-pointer text-decoration-underline'>S.No <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('lotNo')} className='cursor-pointer text-decoration-underline'>Lot No <Icon size='lg' className={getClassNamesFor('lotNo')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('invoiceNo')} className='cursor-pointer text-decoration-underline'>Invoice No <Icon size='lg' className={getClassNamesFor('invoiceNo')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('supplierName')} className='cursor-pointer text-decoration-underline'>Supplier Name <Icon size='lg' className={getClassNamesFor('supplierName')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('receivedSize')} className='cursor-pointer text-decoration-underline'>Received Size <Icon size='lg' className={getClassNamesFor('receivedSize')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('totalQr')} className='cursor-pointer text-decoration-underline'>Total QR <Icon size='lg' className={getClassNamesFor('totalQr')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('dateStamp')} className='cursor-pointer text-decoration-underline'>Date <Icon size='lg' className={getClassNamesFor('dateStamp')} icon='FilterList' /></th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                {filteredData.map((item: any) => (
                                    <tbody key={item.jumboRollStockId}>
                                        <tr>
                                            <td>{item.sno}</td>
                                            <td>{item.lotNo}</td>
                                            <td>{item.invoiceNo}</td>
                                            <td>{item.supplierName}</td>
                                            <td>{item.receivedSize}</td>
                                            <td>{item.totalQr}</td>
                                            <td>{item.dateStamp}</td>
                                            <td className="d-print-none"><Button color='success' isLight icon='Download' aria-label='Download' onClick={() => onPrint(item)}></Button></td>
                                        </tr>
                                    </tbody>
                                ))}
                            </table>
                        </CardBody>
                        <PaginationButtons className="d-print-none" data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                    </Card> : null
                }

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </Page>
        </PageWrapper >
    )
}

export default JumboRollStock