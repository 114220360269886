import React, { useContext, useEffect, useState } from 'react'
import PageWrapper from '../../layout/PageWrapper/PageWrapper'
import Page from '../../layout/Page/Page'
import Collapse from '../bootstrap/Collapse'
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card'
import FormGroup from '../bootstrap/forms/FormGroup'
import Input from '../bootstrap/forms/Input'
import Button, { ButtonGroup } from '../bootstrap/Button'
import { useFormik } from 'formik'
import Select from '../bootstrap/forms/Select'
import Icon from '../icon/Icon'
import { getLicenseKey } from '../../services/application.settings'
import AuthContext from '../../contexts/authContext'
import { showLoader } from '../../services/loader.services'
import AlertService from '../../services/alert.service'
import NoDataMsg from '../../common/components/NoDataMsg'
import InputGroup from '../bootstrap/forms/InputGroup'
import useSortableData from '../../hooks/useSortableData'
import PaginationButtons, { dataPagination } from '../PaginationButtons'
import { addRibbonRollStock, getRibbonRollStock, getRibbonRollStockCount } from '../../services/stockService'
import OpenCardComponent from '../../common/components/OpenCardComponent'
import { toasts } from '../../services/toaste.service'
import { getSubModuleType } from '../../services/common.service'
import SearchableSelect from '../../common/components/SearchableSelect'

const RibbonRollStock = () => {

    useEffect(() => {
        getLotDetailsList()
        getRibbonRollStockList()
    }, [])

    const ribbonRollStockForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            invoiceNo: '',
            supplierName: '',
            orderQty: '',
            receivedQty: '',
            sizeOfRibbon: '',
            ribbonQuality: '',
            totalQr: '',
            lotId: '',
            lotNo: ''
        },
        validate: (values: any) => {
            const errors: {
                invoiceNo?: string;
                supplierName?: string;
                orderQty?: string;
                receivedQty?: string;
                sizeOfRibbon?: string;
                ribbonQuality?: string;
                totalQr?: string;
                lotId?: string;
                lotNo?: string;
            } = {};
            if (!values.invoiceNo) {
                errors.invoiceNo = 'Required';
            }
            if (!values.supplierName) {
                errors.supplierName = 'Required';
            }
            if (!values.orderQty) {
                errors.orderQty = 'Required';
            }
            if (!values.receivedQty) {
                errors.receivedQty = 'Required';
            }
            if (!sizeOfRibbon?.value && isVisibleOnSizeOfRibbon == true) {
                errors.sizeOfRibbon = 'Required';
            }
            if (!sizeOfRibbon && isVisibleOnSizeOfRibbon == false) {
                errors.sizeOfRibbon = 'Required';
            }
            if (!values.ribbonQuality) {
                errors.ribbonQuality = 'Required';
            }
            if (!values.totalQr) {
                errors.totalQr = 'Required';
            }
            if (!lotId && isVisibleOnMillLotNo == true) {
                errors.lotId = 'Required';
            }
            if (!lotNo && isVisibleOnMillLotNo == false) {
                errors.lotNo = 'Required';
            }

            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { addJumboRollStockSubmit() },
    });

    const columnVisibileForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [ribbonRollStockData, serRibbonRollStockData] = useState<any>([])
    const [noDataMsg, setNoDataMsg] = useState('')
    const [isLoader, setIsLoader] = useState(false)

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(ribbonRollStockData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [lotDetailsData, setLotDetailsData] = useState<any>([])
    const [sizeOfRibbonData, setSizeOfRibbonData] = useState<any>([])
    const [isVisibleOnMillLotNo, setIsVisibleOnMillLotNo] = useState(false)
    const [isVisibleOnSizeOfRibbon, setIsVisibleOnSizeOfRibbon] = useState(false)
    const [sizeOfRibbon, setSizeOfRibbon] = useState<any>('')
    const [lot, setLot] = useState<any>([])
    const [lotNo, setLotNo] = useState('')
    const [lotId, setLotId] = useState('')

    // Filter
    const filteredData = onCurrentPageData.filter(
        (i) =>
            i.sno !== null && i.sno.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.lotNo !== null && i.lotNo.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.invoiceNo !== null && i.invoiceNo.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.supplierName !== null && i.supplierName.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.orderQty !== null && i.orderQty.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.receivedQty !== null && i.receivedQty.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.sizeOfRibbon !== null && i.sizeOfRibbon.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.ribbonQuality !== null && i.ribbonQuality.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.totalQr !== null && i.totalQr.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.dateStamp !== null && i.dateStamp.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()),
    );

    const toggleVisibilityOnMillLotNo = () => {
        setIsVisibleOnMillLotNo(!isVisibleOnMillLotNo);
        setLotNo('')
        setLotId('')
        setLot([])
    };

    const selectLotNo = (e: any) => {
        setSizeOfRibbon('')
        if (isVisibleOnMillLotNo) {
            let selectedData: any = lotDetailsData.find((data: any) => data.subModuleTypeId == e?.value);
            setLotNo(selectedData?.subModuleType)
            setLotId(selectedData?.subModuleTypeId)
            setLot(e)
            getSizeofRibbonList(selectedData?.subModuleTypeId)
        } else {
            setLotNo(e.target.value)
        }
    };

    const selectSizeOfRibbon = (e: any) => {
        if (isVisibleOnSizeOfRibbon) {
            setSizeOfRibbon(e)
        } else {
            setSizeOfRibbon(e.target.value)
        }
    };

    function getLotDetailsList() {
        getSubModuleType(1, 2,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.subModuleType;
                    if (data != undefined) {
                        setLotDetailsData(data)
                        setIsVisibleOnMillLotNo(true)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setIsVisibleOnMillLotNo(false)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSizeofRibbonList(lotId: any) {
        getRibbonRollStockCount(lotId, 0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.ribbonRollStockCount;
                    if (data != undefined) {
                        setSizeOfRibbonData(data)
                        setIsVisibleOnSizeOfRibbon(true)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setIsVisibleOnSizeOfRibbon(false)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setJumboRollStockDetails() {
        return ({
            licenseKey: getLicenseKey,
            lotNo: lotNo,
            invoiceNo: ribbonRollStockForm.values.invoiceNo,
            supplierName: ribbonRollStockForm.values.supplierName,
            orderQty: ribbonRollStockForm.values.orderQty,
            receivedQty: ribbonRollStockForm.values.receivedQty,
            sizeOfRibbon: isVisibleOnSizeOfRibbon ? sizeOfRibbon?.value : sizeOfRibbon,
            ribbonQuality: ribbonRollStockForm.values.ribbonQuality,
            totalQr: ribbonRollStockForm.values.totalQr,
            userAccountId: userAccountId,
        })
    }

    function addJumboRollStockSubmit() {
        showLoader(true)
        let jumboRollStockPostData = setJumboRollStockDetails()
        if (ribbonRollStockForm.isValid) {
            addRibbonRollStock(jumboRollStockPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset()
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);

                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (ribbonRollStockForm.isValid == false) {
            toasts("Please fill all details", "Error");
        }
    }

    function getRibbonRollStockList() {
        getRibbonRollStock(0, 0, 0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.ribbonRollStock;
                    if (data != undefined) {
                        serRibbonRollStockData(data)
                        // setIsOpenListCard(false);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function onPrint(i: any) {
        if (i != undefined) {
            let selectedData = i
            window.open(selectedData.pdfPath);
        }
    }

    function closeAndReset() {
        ribbonRollStockForm.resetForm()
        getRibbonRollStockList()
        getLotDetailsList()
        setLotId('')
        setLotNo('')
        setLot([])
        setSizeOfRibbon('')
    }

    return (
        <PageWrapper title={`Generate QR`}>
            <Page>
                <Collapse isOpen={isOpenListCard}>
                    <Card stretch data-tour='list' className='d-print-none' tag="form" noValidate onSubmit={ribbonRollStockForm.handleSubmit}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='QrCode' iconColor='info'>
                                <CardTitle>
                                    Generate QR For Ribbon Roll
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row g-4'>
                                <div className='col-3'>
                                    <InputGroup>
                                        {isVisibleOnMillLotNo ?
                                            <FormGroup id='lotId' label='Lot No' isFloating>
                                                <SearchableSelect
                                                    isFloating
                                                    ariaLabel={''}
                                                    placeholder='Select Lot No'
                                                    className="form-control"
                                                    value={lot}
                                                    isValid={ribbonRollStockForm.isValid}
                                                    onBlur={ribbonRollStockForm.handleBlur}
                                                    list={lotDetailsData.map((data: any) => (
                                                        { value: data.subModuleTypeId, label: data.subModuleType }
                                                    ))}
                                                    onChange={e => selectLotNo(e)} />
                                            </FormGroup> :
                                            <FormGroup id='lotNo' label='Lot No' isFloating>
                                                <Input onInput={selectLotNo}
                                                    value={lotNo}
                                                    isValid={ribbonRollStockForm.isValid}
                                                    onBlur={ribbonRollStockForm.handleBlur}
                                                    isTouched={ribbonRollStockForm.touched.lotNo}
                                                    invalidFeedback={ribbonRollStockForm.errors.lotNo}
                                                    placeholder="Enter Lot No" />
                                            </FormGroup>
                                        }
                                        <Button isOutline color='primary' icon='Add' style={{ height: '46px' }} onClick={toggleVisibilityOnMillLotNo}></Button>
                                    </InputGroup>
                                </div>
                                <div className='col-3'>
                                    <FormGroup id='invoiceNo' label='Invoice No' isFloating>
                                        <Input onChange={ribbonRollStockForm.handleChange} value={ribbonRollStockForm.values.invoiceNo}
                                            isValid={ribbonRollStockForm.isValid}
                                            onBlur={ribbonRollStockForm.handleBlur}
                                            isTouched={ribbonRollStockForm.touched.invoiceNo}
                                            invalidFeedback={ribbonRollStockForm.errors.invoiceNo}
                                            placeholder="Enter Invoice No" />
                                    </FormGroup>
                                </div>
                                <div className='col-3'>
                                    <FormGroup id='supplierName' label='Supplier Name' isFloating>
                                        <Input onChange={ribbonRollStockForm.handleChange} value={ribbonRollStockForm.values.supplierName}
                                            isValid={ribbonRollStockForm.isValid}
                                            onBlur={ribbonRollStockForm.handleBlur}
                                            isTouched={ribbonRollStockForm.touched.supplierName}
                                            invalidFeedback={ribbonRollStockForm.errors.supplierName}
                                            placeholder="Enter Supplier Name" />
                                    </FormGroup>
                                </div>
                                <div className='col-3'>
                                    <FormGroup id='orderQty' label='Order Qty' isFloating>
                                        <Input onChange={ribbonRollStockForm.handleChange} value={ribbonRollStockForm.values.orderQty}
                                            isValid={ribbonRollStockForm.isValid}
                                            onBlur={ribbonRollStockForm.handleBlur}
                                            isTouched={ribbonRollStockForm.touched.orderQty}
                                            invalidFeedback={ribbonRollStockForm.errors.orderQty}
                                            type='number'
                                            placeholder="Enter Order Qty" />
                                    </FormGroup>
                                </div>
                                <div className='col-3'>
                                    <FormGroup id='receivedQty' label='Received Qty' isFloating>
                                        <Input onChange={ribbonRollStockForm.handleChange} value={ribbonRollStockForm.values.receivedQty}
                                            isValid={ribbonRollStockForm.isValid}
                                            onBlur={ribbonRollStockForm.handleBlur}
                                            isTouched={ribbonRollStockForm.touched.receivedQty}
                                            invalidFeedback={ribbonRollStockForm.errors.receivedQty}
                                            type='number'
                                            placeholder="Enter Received Qty" />
                                    </FormGroup>
                                </div>
                                <div className='col-3'>
                                    <InputGroup>
                                        {isVisibleOnSizeOfRibbon ?
                                            <FormGroup id='sizeOfRibbon' label='Size Of Ribbon' isFloating>
                                                <SearchableSelect
                                                    isFloating
                                                    ariaLabel={''}
                                                    placeholder='Select Size Of Ribbon'
                                                    className="form-control"
                                                    value={sizeOfRibbon}
                                                    isValid={ribbonRollStockForm.isValid}
                                                    onBlur={ribbonRollStockForm.handleBlur}
                                                    list={sizeOfRibbonData.map((data: any) => (
                                                        { value: data.sizeOfRibbon, label: data.sizeOfRibbon }
                                                    ))}
                                                    onChange={e => selectSizeOfRibbon(e)} />
                                            </FormGroup> :
                                            <FormGroup id='sizeOfRibbon' label='Size Of Ribbon' isFloating>
                                                <Input onInput={selectSizeOfRibbon}
                                                    value={sizeOfRibbon}
                                                    isValid={ribbonRollStockForm.isValid}
                                                    onBlur={ribbonRollStockForm.handleBlur}
                                                    isTouched={ribbonRollStockForm.touched.sizeOfRibbon}
                                                    invalidFeedback={ribbonRollStockForm.errors.sizeOfRibbon}
                                                    placeholder="Enter Size Of Ribbon" />
                                            </FormGroup>
                                        }
                                        <Button isOutline color='primary' icon='Add' style={{ height: '46px' }} onClick={() => setIsVisibleOnSizeOfRibbon(!isVisibleOnSizeOfRibbon)}></Button>
                                    </InputGroup>
                                </div>
                                {/* <div className='col-3'>
                                    <FormGroup id='sizeOfRibbon' label='Size Of Ribbon' isFloating>
                                        <Input onChange={ribbonRollStockForm.handleChange} value={ribbonRollStockForm.values.sizeOfRibbon}
                                            isValid={ribbonRollStockForm.isValid}
                                            onBlur={ribbonRollStockForm.handleBlur}
                                            isTouched={ribbonRollStockForm.touched.sizeOfRibbon}
                                            invalidFeedback={ribbonRollStockForm.errors.sizeOfRibbon}
                                            placeholder="Enter Size Of Ribbon" />
                                    </FormGroup>
                                </div> */}
                                <div className='col-2'>
                                    <FormGroup id='ribbonQuality' label='Ribbon Quality' isFloating>
                                        <Input onChange={ribbonRollStockForm.handleChange} value={ribbonRollStockForm.values.ribbonQuality}
                                            isValid={ribbonRollStockForm.isValid}
                                            onBlur={ribbonRollStockForm.handleBlur}
                                            isTouched={ribbonRollStockForm.touched.ribbonQuality}
                                            invalidFeedback={ribbonRollStockForm.errors.ribbonQuality}
                                            placeholder="Enter Ribbon Quality" />
                                    </FormGroup>
                                </div>
                                <div className='col-2'>
                                    <FormGroup id='totalQr' label='Total QR' isFloating>
                                        <Input onChange={ribbonRollStockForm.handleChange} value={ribbonRollStockForm.values.totalQr}
                                            isValid={ribbonRollStockForm.isValid}
                                            onBlur={ribbonRollStockForm.handleBlur}
                                            isTouched={ribbonRollStockForm.touched.totalQr}
                                            invalidFeedback={ribbonRollStockForm.errors.totalQr}
                                            type='number'
                                            placeholder="Enter Total QR" />
                                    </FormGroup>
                                </div>
                                <div className='col-md-2'>
                                    <Button className="mt-2" icon="WifiProtectedSetup" color='primary' onClick={ribbonRollStockForm.handleSubmit}>
                                        Generate
                                    </Button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                {/* <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} /> */}

                {ribbonRollStockData != '' ?
                    <Card stretch data-tour='list' className='d-print-none'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle>
                                    Ribbon Roll Stock List
                                </CardTitle>
                            </CardLabel>
                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibileForm.handleChange} value={columnVisibileForm.values.searchInput} />
                            </ButtonGroup>
                        </CardHeader>
                        <CardBody isScrollable>
                            <table className='table table-modern table-hover'>
                                <thead>
                                    <tr>
                                        <th scope='col' onClick={() => requestSort('sno')} className='cursor-pointer text-decoration-underline'>S.No <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('lotNo')} className='cursor-pointer text-decoration-underline'>Lot No <Icon size='lg' className={getClassNamesFor('lotNo')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('invoiceNo')} className='cursor-pointer text-decoration-underline'>Invoice No <Icon size='lg' className={getClassNamesFor('invoiceNo')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('supplierName')} className='cursor-pointer text-decoration-underline'>Supplier Name <Icon size='lg' className={getClassNamesFor('supplierName')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('orderQty')} className='cursor-pointer text-decoration-underline'>Order Qty <Icon size='lg' className={getClassNamesFor('orderQty')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('receivedQty')} className='cursor-pointer text-decoration-underline'>Received Qty <Icon size='lg' className={getClassNamesFor('receivedQty')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('sizeOfRibbon')} className='cursor-pointer text-decoration-underline'>Size Of Ribbon <Icon size='lg' className={getClassNamesFor('sizeOfRibbon')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('snribbonQtyo')} className='cursor-pointer text-decoration-underline'>Ribbon Quantity <Icon size='lg' className={getClassNamesFor('ribbonQuality')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('totalQr')} className='cursor-pointer text-decoration-underline'>Total QR <Icon size='lg' className={getClassNamesFor('totalQr')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('dateStamp')} className='cursor-pointer text-decoration-underline'>Date <Icon size='lg' className={getClassNamesFor('dateStamp')} icon='FilterList' /></th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                {filteredData.map((item: any) => (
                                    <tbody key={item.ribbonRollStockId}>
                                        <tr>
                                            <td>{item.sno}</td>
                                            <td>{item.lotNo}</td>
                                            <td>{item.invoiceNo}</td>
                                            <td>{item.supplierName}</td>
                                            <td>{item.orderQty}</td>
                                            <td>{item.receivedQty}</td>
                                            <td>{item.sizeOfRibbon}</td>
                                            <td>{item.ribbonQuality}</td>
                                            <td>{item.totalQr}</td>
                                            <td>{item.dateStamp}</td>
                                            <td className="d-print-none"><Button color='success' isLight icon='Download' aria-label='Download' onClick={() => onPrint(item)}></Button></td>
                                        </tr>
                                    </tbody>
                                ))}
                            </table>
                        </CardBody>
                        <PaginationButtons className="d-print-none" data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                    </Card> : null
                }

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </Page>
        </PageWrapper >
    )
}

export default RibbonRollStock