import React, { useContext, useEffect, useState } from 'react'
import PageWrapper from '../../layout/PageWrapper/PageWrapper'
import Page from '../../layout/Page/Page'
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card'
import Input from '../bootstrap/forms/Input'
import { ButtonGroup } from '../bootstrap/Button'
import { useFormik } from 'formik'
import Icon from '../icon/Icon'
import AuthContext from '../../contexts/authContext'
import { TableLoader } from '../../services/loader.services'
import NoDataMsg from '../../common/components/NoDataMsg'
import useSortableData from '../../hooks/useSortableData'
import PaginationButtons, { dataPagination } from '../PaginationButtons'
import { getScannedRibbonRollStock } from '../../services/stockService'
import { toasts } from '../../services/toaste.service'

const ribbonRollScannedList = () => {

    useEffect(() => {
        getScannedRibbonRollStockList()
    }, [])

    const columnVisibileForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [scannedData, setScannedData] = useState<any>([])
    const [noDataMsg, setNoDataMsg] = useState('')

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(scannedData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [dataStatus, setDataStatus] = useState(false)

    // Filter
    const filteredData = onCurrentPageData.filter(
        (i) =>
            i.sno !== null && i.sno.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.lotNo !== null && i.lotNo.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.sizeOfRibbon !== null && i.sizeOfRibbon.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            // i.ribbonQuality !== null && i.ribbonQuality.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()) ||
            i.stockCount !== null && i.stockCount.toString().toLowerCase().includes(columnVisibileForm.values.searchInput.toLowerCase()),
    );

    function getScannedRibbonRollStockList() {
        getScannedRibbonRollStock(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.scannedRibbonRollStockList;
                    if (data != undefined) {
                        setScannedData(data)
                        setDataStatus(true)
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setNoDataMsg(response.data.message)
                    setDataStatus(true)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    return (
        <PageWrapper title={`Generate QR`}>
            <Page>
                <Card stretch data-tour='list' className='d-print-none'>
                    <CardHeader borderSize={1}>
                        <CardLabel icon='List' iconColor='info'>
                            <CardTitle>
                                Ribbon Roll Stock List
                            </CardTitle>
                        </CardLabel>

                        <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                            <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                            <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibileForm.handleChange} value={columnVisibileForm.values.searchInput} />
                        </ButtonGroup>
                    </CardHeader>
                    <CardBody isScrollable>
                        {!dataStatus ?
                            <TableLoader /> :
                            <table className='table table-modern table-hover'>
                                <thead>
                                    <tr>
                                        <th scope='col' onClick={() => requestSort('sno')} className='cursor-pointer text-decoration-underline'>S.No <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('customerName')} className='cursor-pointer text-decoration-underline'>Lot No <Icon size='lg' className={getClassNamesFor('customerName')} icon='FilterList' /></th>
                                        <th scope='col' onClick={() => requestSort('sizeOfRibbon')} className='cursor-pointer text-decoration-underline'>Size Of Ribbon <Icon size='lg' className={getClassNamesFor('sizeOfRibbon')} icon='FilterList' /></th>
                                        {/* <th scope='col' onClick={() => requestSort('ribbonQuality')} className='cursor-pointer text-decoration-underline'>Ribbon Quality <Icon size='lg' className={getClassNamesFor('ribbonQuality')} icon='FilterList' /></th> */}
                                        <th scope='col' onClick={() => requestSort('totalScannedCount')} className='cursor-pointer text-decoration-underline'>Total Roll <Icon size='lg' className={getClassNamesFor('totalScannedCount')} icon='FilterList' /></th>
                                    </tr>
                                </thead>
                                {scannedData != "" ? <>{filteredData.map((item: any) => (<tbody key={item.ribbonRollStockId}><tr><td>{item.sno}</td><td>{item.lotNo}</td><td>{item.sizeOfRibbon}</td><td>{item.stockCount}</td></tr></tbody>))}</> : <NoDataMsg columnsCount={10} msg={noDataMsg} />}
                            </table>
                        }
                    </CardBody>
                    <PaginationButtons className="d-print-none" data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                </Card>
            </Page>
        </PageWrapper >
    )
}

export default ribbonRollScannedList