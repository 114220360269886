import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getJumboRollStock = (jumboRollStockId: any, isDropdown: any, junkNumber: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getJumboRollStock/${getLicenseKey}/${jumboRollStockId}/${isDropdown}/${junkNumber}`,
        response => {
            success(response)
        },
        error => {
            failure("getJumboRollStock - " + error.message)
            console.log('error caught in service : getJumboRollStock')
        },
    );

export const addJumboRollStock = (jumboRollStockPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addJumboRollStock`, jumboRollStockPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addJumboRollStock')
        }
    );


export const getRibbonRollStock = (jumboRollStockId: any, isDropdown: any, junkNumber: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getRibbonRollStock/${getLicenseKey}/${jumboRollStockId}/${isDropdown}/${junkNumber}`,
        response => {
            success(response)
        },
        error => {
            failure("getRibbonRollStock - " + error.message)
            console.log('error caught in service : getRibbonRollStock')
        },
    );

export const addRibbonRollStock = (ribbonRollStockPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addRibbonRollStock`, ribbonRollStockPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addRibbonRollStock')
        }
    );

export const getScannedJumboRollStock = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getScannedJumboRollStockList/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getScannedJumboRollStockList - " + error.message)
            console.log('error caught in service : getScannedJumboRollStockList')
        },
    );

export const getScannedRibbonRollStock = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getScannedRibbonRollStockList/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getScannedRibbonRollStockList - " + error.message)
            console.log('error caught in service : getScannedRibbonRollStockList')
        },
    );

export const getScannedProductionEntryList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getScannedProductionEntryList/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getScannedProductionEntryList - " + error.message)
            console.log('error caught in service : getScannedProductionEntryList')
        },
    );

export const addJumboRollStockDispatch = (dispatchPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addJumboRollStockDispatch`, dispatchPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addJumboRollStockDispatch')
        }
    );

export const getJumboRollStockDispatch = (jumboRollStockId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getJumboRollStockDispatch/${getLicenseKey}/${jumboRollStockId}`,
        response => {
            success(response)
        },
        error => {
            failure("getJumboRollStockDispatch - " + error.message)
            console.log('error caught in service : getJumboRollStockDispatch')
        },
    );

export const addRibbonRollStockDispatch = (dispatchPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addRibbonRollStockDispatch`, dispatchPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addRibbonRollStockDispatch')
        }
    );

export const getRibbonRollStockDispatch = (ribbonRollStockId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getRibbonRollStockDispatch/${getLicenseKey}/${ribbonRollStockId}`,
        response => {
            success(response)
        },
        error => {
            failure("getRibbonRollStockDispatch - " + error.message)
            console.log('error caught in service : getRibbonRollStockDispatch')
        },
    );

export const getPackingList = (orderReceivedDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getPackingList/${getLicenseKey}/${orderReceivedDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getPackingList - " + error.message)
            console.log('error caught in service : getPackingList')
        },
    );

export const addQuotation = (quotationPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addQuotation`, quotationPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addQuotation')
        }
    );

export const getQuotation = (quotationId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getQuotation/${getLicenseKey}/${quotationId}`,
        response => {
            success(response)
        },
        error => {
            failure("getQuotation - " + error.message)
            console.log('error caught in service : getQuotation')
        },
    );

export const updateQuotation = (quotationPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateQuotation`, quotationPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateQuotation')
        }
    );

export const addDispatchDetails = (dispatchPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addDispatchDetails`, dispatchPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addDispatchDetails')
        }
    );

export const getDispatchDetails = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getDispatchDetails/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getDispatchDetails - " + error.message)
            console.log('error caught in service : getDispatchDetails')
        },
    );

export const getRibbonRollStockCount = (lotId: any, isDropdown: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getRibbonRollStockCount/${getLicenseKey}/${lotId}/${isDropdown}`,
        response => {
            success(response)
        },
        error => {
            failure("getRibbonRollStockCount - " + error.message)
            console.log('error caught in service : getRibbonRollStockCount')
        },
    );

export const getJumboRollStockCount = (lotId: any, isDropdown: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getJumboRollStockCount/${getLicenseKey}/${lotId}/${isDropdown}`,
        response => {
            success(response)
        },
        error => {
            failure("getJumboRollStockCount - " + error.message)
            console.log('error caught in service : getJumboRollStockCount')
        },
    );